import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
	Box,
	Popover,
	ToggleButton,
	Typography
} from "@mui/material";
import React from "react";
import { BiFont, BiSolidColorFill } from "react-icons/bi";
import { Colours } from "../../../assets/global/Theme-variable";
import PopUpColourPicker from "./PopUpColourPicker";

export default function IntegratedColourPicker({
	handleColourChange,
	selectedColour,
	type,
	ref,
	label,
	disabled
}) {
	
	console.log(disabled)
	let currentColour = selectedColour;


	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpenPopover = (event) => {
		console.log('open popover')
		if (anchorEl !== null) {
			handleClosePopover();
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClosePopover = () => {
		setAnchorEl(null);
	};

	const handleColour = (colour) => {
		handleColourChange(colour);
		handleClosePopover();
	};

	const popoverOpen = Boolean(anchorEl);
	const id = popoverOpen ? "simple-popover" : undefined;

	return (
		<>
			{type === "dataGridFilter" ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						height: "100%",
						// pl: "20px",
						// pb: 1,
						// width: "100%",
						// "&:hover": {
						borderBottom: `1px solid ${Colours.gsgrey}`,
						// },
						"&:hover": {
							borderBottom: `2px solid black`,
						},
					}}
					onClick={!disabled ? handleOpenPopover : null}
					>
					<Typography
						sx={{
							fontSize: 12,
							// mr: 1,
							ml: 2,
							position: "relative",
							top: -1,
							// left: 3,
							lineHeight: "1.4375em",
							backgroundColor: "white",
							pb: 0.5,
						}}
					>
						{label}
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "flex-start",
							height: "100%",
							// pl: "20px",
							// width: "100%",
						}}
					>
						<Box
							sx={{
								backgroundColor: `${currentColour}`,
								width: 40,
								flex: 1,
								height: "85%",
								mr: 1,
								ml: 2,
								// m: 2,
								// height: '',
								border:
									selectedColour === "#ffffff" ||
									selectedColour === undefined ||
									selectedColour === "white" ||
									currentColour === "white"
										? `1px solid ${Colours.gsgrey}`
										: null,
								borderRadius: 1,
								// position:
								// 	type !== "bulk-bg" &&
								// 	type !== "bulk-f" &&
								// 	type !== "dataGridFilter" &&
								// 	"relative",
								// top:
								// 	type !== "bulk-bg" &&
								// 	type !== "bulk-f" &&
								// 	type !== "dataGridFilter" &&
								// 	-22,
								// left: 0,
								// margin: 0.3,
								mb: 1,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						></Box>
						<ArrowDropDownIcon sx={{ color: Colours.gsblue }} />
					</Box>
					<Popover
						id={id}
						open={popoverOpen}
						anchorEl={anchorEl}
						onClose={handleClosePopover}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						<PopUpColourPicker
							selectedColour={selectedColour}
							handleColourChange={handleColour}
							type="button"
							label="Button colour"
							ref={ref ? ref : null}
						/>
					</Popover>
				</Box>
			) : (
				<>
					<Box
						sx={{
							bgcolor: "none",
							display: "flex",
							flexWrap: "wrap",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							border:
								type !== "bulk-bg" &&
								type !== "bulk-f" &&
								type !== "dataGridFilter" &&
								"1px solid #c4c4c4",
							borderRadius:
								type !== "bulk-bg" &&
								type !== "bulk-f" &&
								type !== "dataGridFilter" &&
								1,
							p: (type === "bulk-bg") | (type === "bulk-f") ? 0 : 0.9,
							width:
								type === "tagField"
									? "25%"
									: type === "bulk-bg" || type === "bulk-f"
									? "100%"
									: "35%",
							height: type === "bulk-bg" || type === "bulk-f" ? "100%" : 40,
							// m: (type === "bulk-bg") | (type === "bulk-f") && 0.3,
						}}
					>
						{type !== "bulk-bg" && type !== "bulk-f" && (
							// type !== "dataGridFilter" &&
							<Typography
								sx={{
									fontSize: 12,
									mr: 1,
									position: "relative",
									top: -20,
									left: 3,
									backgroundColor: "white",
									p: 0.5,
								}}
							>
								{label}
							</Typography>
						)}
						{type === "bulk-bg" ? (
							<ToggleButton
								value={selectedColour}
								size="small"
								aria-label="color"
								onClick={!disabled ? handleOpenPopover : null}
								sx={{
									display: "flex",
									flexDirection: "column",
									border: "none",
									justifyContent: "center",
									alignItems: "center",
									// p: 0,
									m: 0.1,
									height: "100%",
								}}
								ref={ref ? ref : null}
							>
								<BiSolidColorFill size={20} />

								{/* <ArrowDropDownIcon /> */}
								<Box
									sx={{
										backgroundColor: `${currentColour}`,
										width: type === "bulk-bg" || type === "bulk-f" ? 25 : 20,
										height: type === "bulk-bg" || type === "bulk-f" ? 5 : 20,
										borderRadius:
											type === "bulk-bg" || type === "bulk-f" ? 0.5 : 1,
										position:
											type !== "bulk-bg" && type !== "bulk-f" && "relative",
										top: type !== "bulk-bg" && type !== "bulk-f" && -22,
										left: 0,
										// margin: 0.3,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										border:
											selectedColour === "#ffffff"
												? "1px solid #c4c4c4"
												: selectedColour === "white"
												? "1px solid #c4c4c4"
												: currentColour === "white"
												? "1px solid #c4c4c4"
												: null,
									}}
									onClick={!disabled ? handleOpenPopover : null}
									></Box>
							</ToggleButton>
						) : type === "bulk-f" ? (
							<ToggleButton
								value={selectedColour}
								aria-label="color"
								size="small"
								onClick={!disabled ? handleOpenPopover : null}
								sx={{
									display: "flex",
									flexDirection: "column",
									border: "none",
									justifyContent: "center",
									alignItems: "center",
									// p: 20,
									m: 0.1,
									height: "100%",
								}}
								ref={ref ? ref : null}
								disabled={disabled}
							>
								<BiFont size={20} />
								{/* <ArrowDropDownIcon /> */}
								<Box
									sx={{
										backgroundColor: `${currentColour}`,
										width: type === "bulk-bg" || type === "bulk-f" ? 25 : 20,
										height: type === "bulk-bg" || type === "bulk-f" ? 5 : 20,
										borderRadius:
											type === "bulk-bg" || type === "bulk-f" ? 0.5 : 1,
										position:
											type !== "bulk-bg" && type !== "bulk-f" && "relative",
										top: type !== "bulk-bg" && type !== "bulk-f" && -22,
										left: 0,
										// margin: 0.3,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										border:
											selectedColour === "#ffffff"
												? "1px solid #c4c4c4"
												: selectedColour === "white"
												? "1px solid #c4c4c4"
												: currentColour === "white"
												? "1px solid #c4c4c4"
												: null,
									}}
									onClick={!disabled ? handleOpenPopover : null}
									></Box>
							</ToggleButton>
						) : (
							<Box
								sx={{
									backgroundColor: `${currentColour}`,
									width:
										type === "bulk-bg" || type === "bulk-f"
											? 25
											: type === "dataGridFilter"
											? 40
											: 20,
									height: type === "bulk-bg" || type === "bulk-f" ? 10 : 20,
									border:
										selectedColour === "#ffffff" ||
										selectedColour === undefined ||
										selectedColour === "white" ||
										currentColour === "white"
											? "1px solid #c4c4c4"
											: null,
									borderRadius:
										type === "bulk-bg" || type === "bulk-f"
											? 0.5
											: type === "dataGridFilter"
											? 0
											: 1,
									position:
										type !== "bulk-bg" &&
										type !== "bulk-f" &&
										type !== "dataGridFilter" &&
										"relative",
									top:
										type !== "bulk-bg" &&
										type !== "bulk-f" &&
										type !== "dataGridFilter" &&
										-22,
									left: 0,
									// margin: 0.3,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",

									"&:hover": {
										backgroundColor: type === "dataGridFilter" && "pink",
									},
								}}
								onClick={!disabled ? handleOpenPopover : null}
								></Box>
						)}
					</Box>
					<Popover
						id={id}
						open={popoverOpen}
						anchorEl={anchorEl}
						onClose={handleClosePopover}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						
					>
						<PopUpColourPicker
							selectedColour={selectedColour}
							handleColourChange={handleColour}
							type="button"
							label="Button colour"
							ref={ref ? ref : null}
						/>
					</Popover>
				</>
			)}
		</>
	);
}
