export const getRows = (gridList, data, contextName,table,type) => {
    
    let rows=[]
    // console.log(data, contextName)
    switch (gridList.gridPage) {
        case "screens":
            rows = data[contextName]?.filter(
                (screen) =>
                    !screen.hasOwnProperty("list_variant") || screen.list_variant === 0
            );
            break;
        case "analysisCategory":
            rows = data[contextName]
                    ?.filter(
                        (cat) =>
                            cat.analysisCategory_number!== "0" && cat.analysisCategory_number!== "100"
                    )
                    .sort((a, b) => a[type] - b[type])
            
            break;
        case "pricebands":
            rows = data[contextName]
                    ?.filter((cat) => cat.priceband_number!== "1")
                    .sort((a, b) => a[type] - b[type])
            
            break;
            case "staff":
                rows = data[contextName]
                ?.filter((i) => i.server_number !== "419" && i.server_number !== 419)
                .sort((a, b) => a[type] - b[type])
                break;
            default: rows = data[contextName]
    }
    return rows?.filter((i)=> i[`${table}_isDeleted`] === 0)
}

export const getAllRows = (gridList, data, contextName,table,type) => {
    let rows;
    switch (gridList.gridPage) {
        case "screens":
            rows = data[contextName]?.filter(
                (screen) =>
                    !screen.hasOwnProperty("list_variant") || screen.list_variant === 0
            );
            break;
        case "analysisCategory":
            rows = data[contextName]
                    ?.filter(
                        (cat) =>
                            cat.analysisCategory_number!== "0" && cat.analysisCategory_number!== "100"
                    )
                    .sort((a, b) => a[type] - b[type])
            
            break;
        case "pricebands":
            rows = data[contextName]
                    ?.filter((cat) => cat.priceband_number!== "1")
                    .sort((a, b) => a[type] - b[type]).filter((i)=> i[`${table}_isDeleted`] === 0)
            
            break;
            case "staff":
                rows = data[contextName]
                ?.filter((i) => i.server_number !== "419" && i.server_number !== 419)
                .sort((a, b) => a[type] - b[type])
                break;
            default: rows = data[contextName]
    }
    console.log(rows)
    return rows
}