import { useAuth0 } from "@auth0/auth0-react";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	List,
	ListItem,
	Modal,
	Slider, Stack,
	Switch,
	Typography
} from "@mui/material";
import axios from "axios";
import React from "react";
import { API_ENDPOINTS } from "../../../API/apiConfig";
import { DataGridContext } from "../../../context/DataGridContext";
import { useUserState } from "../../../context/UserContext";
import AccessControl, { checkPermissions } from "../../AccessControl";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "20px",
	width: 500,
	// minHeight: 400,
	bgcolor: "white",
	boxShadow: 24,
	p: 4,
	display: "flex",
	flexDirection: "column",
	alignItems: "stretch",
	gap: 2,
};

export default function AdvancedSettings({
	modalVisible,
	setModalVisible,
	newChanges,
	setNewChanges,
	// variant,
	variantOpts,
	// setVariant,
	contextName,
}) {
	const {  data, setData, variant, setVariant } = React.useContext(DataGridContext);
	const userPermission = useUserState()
	const { getAccessTokenSilently } = useAuth0();
	const [isSaving, setIsSaving] = React.useState(false);
	const [listCopy, setListCopy] = React.useState();

	const handleRangeChange = (event, newValue) => {
		setListCopy((prev) => ({
			...prev,
			list_details: {
				...prev.list_details,
				selMin: newValue[0],
				selMax: newValue[1],
			},
		}));
	};

	const handleStaydown = (event) => {
		setListCopy((prev) => ({
			...prev,
			list_details: {
				...prev.list_details,
				staydown: event.target.checked === true ? 1 : 0,
			},
		}));
	};

	const [checked, setChecked] = React.useState([]);

	React.useEffect(() => {
		if (variant !== undefined && variant?.list_number) {
			setChecked(
				// variant.list_variant === 0 && variantOpts.length === 1 ? data.sites
				// .map((site) => site.site_number) :
					variant?.list_variant === 0 ? data.sites
			.filter((site) =>
				!site?.site_details?.screen_variants?.some(
					(obj) =>
						obj?.hasOwnProperty(JSON.parse(variant?.list_number)) &&
						obj[variant?.list_number] !== variant?.list_variant
				)).map((site) => site.site_number) :
					data.sites
					.filter((site) =>
						site?.site_details?.screen_variants?.some(
							(obj) =>
								obj?.hasOwnProperty(JSON.parse(variant?.list_number)) &&
								obj[variant?.list_number] === variant?.list_variant
						)).map((site) => site.site_number)
			);
			setListCopy(variant);
		}
	}, [variant]);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleSave = async (value) => {
		setIsSaving(true);

		const originalArr = data?.sites
			.filter((site) =>
				site?.site_details?.screen_variants?.some(
					(obj) =>
						obj?.hasOwnProperty(JSON.parse(variant?.list_number)) &&
						obj[variant?.list_number] === variant?.list_variant
				)
			)
			.map((obj) => obj.site_number);

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: [listCopy],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.post(API_ENDPOINTS.list, newRowData, config) // using endpoint from config
			.then(function (response) {
				console.log(response);
				setTimeout(() => {
					const newData = [...data[contextName]];
					// const newDataAll = [...data[contextName]];

					// const foundIndexAll = newDataAll.findIndex(
					// 	(x) => JSON.parse(x.list_uid) === JSON.parse(listCopy.list_uid)
					// );
					// console.log(newData)
					const foundIndex = newData.findIndex(
						(x) => parseInt(x.list_uid) === parseInt(listCopy.list_uid)
					);
					newData[foundIndex] = listCopy;
					// newDataAll[foundIndexAll] = listCopy;
					setData({ ...data, [contextName]: newData });
					setVariant(listCopy);
				}, 1000);
			})
			.catch(function (error) {
				console.log("res", error);
				// setIsSaving(false);
			});

		const newData = [...data.sites];

		let sitesToUpdate = [];
		data.sites.forEach((site) => {
			if (!site.site_details.screen_variants) {
				site.site_details.screen_variants = [];
			}
			if (checked.includes(site.site_number)) {
				// if checked arr contains the site_number and the site already has a variant for this list number,
				// delete existing
				if (
					site?.site_details?.screen_variants?.some(
						(obj) =>
							obj?.hasOwnProperty(JSON.parse(variant?.list_number)) &&
							obj[variant?.list_number] === variant?.list_variant
					)
				) {
					return;
					// console.log("site with list number and same variant", site);
				} else if (
					site?.site_details?.screen_variants?.some(
						(obj) =>
							obj?.hasOwnProperty(JSON.parse(variant?.list_number)) &&
							obj[variant?.list_number] !== variant?.list_variant
					)
				) {
					// console.log("site with list number but different variant", site);
					site.site_details.screen_variants =
						site?.site_details?.screen_variants.filter(
							(screen) => !screen.hasOwnProperty([variant.list_number])
						);
					const variantObj = new Map([
						[variant.list_number, variant.list_variant],
					]);
					const obj = Object.fromEntries(variantObj);

					site.site_details.screen_variants.push(obj);
					sitesToUpdate.push(site);
					let foundIndex = newData.findIndex(
						(x) => x.site_number === site.site_number
					);
					newData[foundIndex] = site;
				} else if (
					!site?.site_details?.screen_variants?.some(
						(obj) =>
							obj?.hasOwnProperty(JSON.parse(variant?.list_number)) &&
							obj[variant?.list_number] === variant?.list_variant
					)
				) {
					//  add new variant
					// console.log("site without a variant for this list number", site);
					const variantObj = new Map([
						[variant.list_number, variant.list_variant],
					]);
					const obj = Object.fromEntries(variantObj);

					site.site_details.screen_variants.push(obj);
					sitesToUpdate.push(site);
					let foundIndex = newData.findIndex(
						(x) => x.site_number === site.site_number
					);
					newData[foundIndex] = site;
							}
			}

			// if the checked array doesn't contain the site number, but the site has this variant, delete it
			else if (
				!checked.includes(site.site_number) &&
				originalArr.includes(site.site_number)
			) {
				// console.log("checked doesnt inclide site number, but orig does, site");
				const newVariants = site?.site_details?.screen_variants?.filter(
					(obj) => {
						return (
							!obj?.hasOwnProperty(JSON.parse(variant?.list_number)) &&
							obj[variant?.list_number] !== variant?.list_variant
						);
					}
				);
				site.site_details.screen_variants =
					newVariants !== undefined ? newVariants : [];
				sitesToUpdate.push(site);
			}
		});

		const siteUpdate = {
			updates: Array.isArray(sitesToUpdate) ? sitesToUpdate : [sitesToUpdate],
		};

		if (siteUpdate.updates.length > 0) {
			axios
				.post(API_ENDPOINTS.site, siteUpdate, config) // using endpoint from config
				.then(function (response) {
					console.log(response);
					setData({ ...data, sites: newData });

					setTimeout(() => {
						setIsSaving(false);
						setModalVisible(false);
					}, 1000);
				})
				.catch(function (error) {
					console.log("res", error);
					setIsSaving(false);
				});
		} else {
			setTimeout(() => {
				setIsSaving(false);
				setModalVisible(false);
			}, 1000);
		}
	};

	return (
		<Modal open={modalVisible}>
			<Box sx={style}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 2,
					}}
				>
					<Typography variant="h3">Advanced Settings</Typography>
					<Button
						variant="contained"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => setModalVisible(!modalVisible)}
					>
						X
					</Button>
				</Box>
				{/* <OptionSelect
					// option={
					// 	listArea === "Bottom Bar"
					// 		? bottomLayout
					// 		: // : listArea === "Standard List"
					// 		  // ? standardListLayout
					// 		  layout
					// }
					options={[1, 2, 3, 4]}
					value={stayDown}
					type="staydown"
					label={"Stay Down"}
					onChange={(event) => setStayDown(event.target.value)}
					// width="10%"
				/> */}
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "flex-start",
						gap: 10,
						height: 60,
					}}
				>
					<FormControlLabel
						// value={variant.list_details.stayDown === 1 ? true : false}
						control={
							<Checkbox
								// disabled={checkPermissions(userPermission, {
								// 	page: "lists",
								// 	allow: false,
								// 	action: "edit",
								// })}
								checked={listCopy?.list_details?.staydown === 1 ? true : false}
								onChange={handleStaydown}
								inputProps={{ "aria-label": "show text" }}
								disableRipple
								sx={{
									"& .label": {
										marginLeft: 0,
									},
									justifySelf: "flex-end",
									// MuiButtonBase-root-MuiCheckbox-root
									"& .MuiCheckbox": {
										padding: 0,
									},
									"& .MuiButtonBase-root": {
										padding: 0,
									},
									p: 0,
									pt: 0.9,
								}}
								disabled={!checkPermissions(userPermission, {
									page: "screens",
									strict: false,
									action: "edit",
								})}
							/>
						}
						label={
							<Typography
								sx={{
									fontSize: 15,
									marginLeft: 0,
									"& .MuiFormControlLabel-root MuiFormControlLabel-labelPlacementStart":
										{
											marginLeft: 0,
										},
								}}
							>
								Staydown
							</Typography>
						}
						labelPlacement="top"
						sx={{
							marginLeft: 0,
							alignItems: "flex-start",
						}}
					/>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							justifyContent: "center",
						}}
					>
						<Typography
							sx={{ fontSize: 15, textAlign: "center" }}
							id="input-slider"
						>
							Number of Items
						</Typography>
						<Stack
							spacing={2}
							direction="row"
							sx={{ width: "100%", pt: 0.9 }}
							alignItems="center"
						>
							<Typography
								sx={{ fontSize: 12, textAlign: "center", lineHeight: 1 }}
							>
								{listCopy?.list_details?.selMin} min
							</Typography>
							<Slider
								getAriaLabel={() => "Item selection range"}
								value={[
									listCopy?.list_details?.selMin,
									listCopy?.list_details?.selMax,
								]}
								onChange={handleRangeChange}
							
								disabled={!checkPermissions(userPermission, {
									page: "screens",
									strict: false,
									action: "edit",
								})}
								min={0}
								max={10}
								sx={{
									"& .MuiSlider-thumb": {
										height: 15,
										width: 15,
										// backgroundColor: "pink",
									},
								}}
							/>
							<Typography
								sx={{ fontSize: 12, textAlign: "center", lineHeight: 1 }}
							>
								{listCopy?.list_details?.selMax} max
							</Typography>
						</Stack>
					</Box>
				</Box>
				<Box sx={{ width: "100%" }}>
					<Typography
						sx={{ fontSize: 15, fontWeight: 400, textAlign: "left" }}
						id="input-slider"
					>
						Sites
					</Typography>
					<List
						sx={{
							width: "100%",

							columnCount: 2,
							columnGap: 5,
						}}
					>
						{data?.sites?.map((site) => (
							<ListItem sx={{ p: 0.5 }} key={site?.site_number}>
								{/* <Switch
									edge="start"
									onChange={handleToggle(site.site_number)}
									checked={checked.indexOf(site.site_number) !== -1}
									inputProps={{
										"aria-labelledby": "switch-list-label-wifi",
									}}
								/>
								<ListItemText
									id="switch-list-label-wifi"
									primary={site.site_name}
									primaryTypographyProps={{ fontSize: 13 }}
								/> */}
								<FormControlLabel
									control={
										<Switch
											edge="start"
											onChange={handleToggle(site?.site_number)}
											checked={checked?.indexOf(site?.site_number) !== -1}
											inputProps={{
												"aria-labelledby": "switch-list-label-site",
											}}
											disabled={variant?.list_variant === 0 || checkPermissions(userPermission, {
												page: "screens",
												strict: false,
												action: "edit",
											}) === false}
										/>
									}
									label={
										<Typography sx={{ fontSize: 13 }}>
											{site?.site_name}
										</Typography>
									}
									sx={{
										p: 0,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								/>
							</ListItem>
						))}
					</List>
				</Box>
				{/* <Box
					sx={{
						width: "100%",
					}}
				>
					<FormControlLabel
						value={priority}
						control={
							<Checkbox
								checked={priority}
								onChange={(event) => setPriority(event.target.checked)}
								inputProps={{"aria-label": "show text"}}
								disableRipple
							/>
						}
						label={
							<Typography
								sx={{
									fontSize: 12,
								}}
							>
								Priority
							</Typography>
						}
						labelPlacement="start"
					/>
				</Box> */}
				{/* <OptionSelect
					options={[0, 1, 2, 3, 4]}
					value={min}
					type="staydown"
					label={"Min Items"}
					onChange={(event) => setMin(event.target.value)}
					// width="10%"
				/>

				<OptionSelect
					options={[0, 1, 2, 3]}
					value={max}
					type="staydown"
					label={"Max Items"}
					onChange={(event) => setMax(event.target.value)}
					// width="10%"
				/> */}
				{/* <OptionSelect
					options={[0, 1, 2, 3, 4]}
					value={link}
					type="staydown"
					label={"Link"}
					onChange={(event) => setLink(event.target.value)}
					// width="10%"
				/> */}
				{/* <OptionSelect
					options={["Normal Action"]}
					value={listType}
					type="staydown"
					label={"List Type"}
					onChange={(event) => setListType(event.target.value)}

					// width="10%"
				/>
				<OptionSelect
					options={["Normal Stack"]}
					value={stackMode}
					type="staydown"
					label={"Stack Mode"}
					onChange={(event) => setStackMode(event.target.value)}

					// width="10%"
				/> */}
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: "screens",
						// allow: false,
						strict: false,

						action: "edit",
					}}
					// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
					renderNoAccess={() => <></>}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							justifySelf: "flex-end",
						}}
					>
						<Button
							variant="outlined"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							onClick={() => setModalVisible(false)}
						>
							Cancel
						</Button>
						<LoadingButton
							size="small"
							onClick={handleSave}
							loading={isSaving}
							variant={!isSaving ? "contained" : "outlined"}
							loadingPosition="start"
							startIcon={<SaveIcon />}
							disabled={isSaving}
						>
							<span>Save</span>
						</LoadingButton>
					</Box>
				</AccessControl>
			</Box>
		</Modal>
	);
}
