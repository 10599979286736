import React from "react";
import lists from "../../grid/data/lists";
import ErrorIcon from "@mui/icons-material/Error";
import { checkPermissions } from "../../AccessControl";
import { DataGridContext } from "../../../context/DataGridContext";
import {
	currencyFormatter,
	getApplyFilterFnPriceBand,
	getApplyFilterFnSalesGroup,
	getApplyFilterFnTagBehaviour,
	getApplyFilterFnTags,

	getListIssuesNewDataGrid,
	
	getVariantCount,
	
	listIssuesFilterOperator,
	numberSort,
	renderTagModules,

	tagsFilterOperators,
	tagsSortComparator,
} from "../utils";
import { DataGridShowColour } from "../DataGridShowColour";
import { Box } from "@mui/system";
import { Chip, Rating, TextField, Typography } from "@mui/material";
import { Colours } from "../../../assets/global/Theme-variable";
import { DataGridCheckbox } from "../../../components/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import {
	GridEditInputCell,
	getGridBooleanOperators,
	getGridSingleSelectOperators,
	useGridApiContext,
} from "@mui/x-data-grid";
import DisplayTagCell from "../data grid files/DisplayTagCell";
import { LightTooltip } from "../data grid files/CustomComponents";
import { StandardAutoComplete } from "../../../components/StandardAutoComplete";
import { AutocompleteChipCell } from "./components/AutoCompleteChipCell";
import { ChipDisplayCell } from "./components/ChipDisplay";
import { PasswordField } from "./components/PasswordField";
import { ColourPicker } from "./components/ColourPicker";
import { StyledTag } from "../../../components/StyledTag";
import IntegratedColourPicker from "../../grid/components/IntegratedColourPicker";
import PriceInputField from "./components/PriceInputField";
import ExpandingTagCell from "../data grid files/ExpandingTagCell";
import { useUserState } from "../../../context/UserContext";
import { ExpansionRow } from "../../../components/ExpansionRow";

export const Config = (gridPage, ref) => {
	const { data } = React.useContext(DataGridContext);
	const userPermission = useUserState()

	function RatingInputValue(props) {
		const { item, applyValue } = props;

		const handleFilterChange = (newValue) => {
			applyValue({ ...item, value: newValue });
		};

		return (
			<Box
				sx={{
					display: "inline-flex",
					flexDirection: "row",
					// alignItems: "center",
					height: "100%",
					pl: "20px",
					width: "100%",
				}}
			>
				<IntegratedColourPicker
					selectedColour={item.value}
					handleColourChange={handleFilterChange}
					type="dataGridFilter"
					label="Colour"
					ref={ref}
				/>
			</Box>
		);
	}

	const colourFilterOperators = [
		{
			label: "is",
			value: "is",
			getApplyFilterFn: (filterItem) => {
				if (!filterItem.field || !filterItem.value || !filterItem.operator) {
					return null;
				}
				return (value) => {
					console.log("value", value, "filteritem", filterItem);
					if (value.value === filterItem.value) return value.row;
				};
			},
			InputComponent: RatingInputValue,
			// InputComponentProps: { type: "number" },
			getValueAsString: (value) => `${value}`,
		},
	];

	const isAnyofOperator = getGridSingleSelectOperators()
		.filter((operator) => operator.value === "isAnyOf")
		.map((operator) => {
			const newOperator = { ...operator };
			const newGetApplyFilterFn = (filterItem, column) => {
				if (!Array.isArray(filterItem.value) || filterItem.value.length < 1) {
					return null;
				}
				return (value) => {
					let isOk = true;
					let arr = [];
					filterItem?.value?.forEach((filterItem) => {
						
						arr.push(
							// parseInt(
							data?.categories?.find(
								(sg) => sg?.salesGroup_number === filterItem
							)?.salesGroup_number
							// )
						);
					});
					const matching = arr?.includes(value);
					return isOk && matching;
				};
			};
			newOperator.getApplyFilterFn = newGetApplyFilterFn;
			return newOperator;
		});

	const pricebandOperator = getGridSingleSelectOperators()
		.filter((operator) => operator.value === "isAnyOf")
		.map((operator) => {
			const newOperator = { ...operator };
			const newGetApplyFilterFn = (filterItem, column) => {
				if (!Array.isArray(filterItem.value) || filterItem.value.length < 1) {
					return null;
				}
				return (value) => {
					let isOk = true;
					let arr = [];
					if (value) {
						const matching = filterItem?.value?.some((j) => {
							return value?.some((i) => i?.priceband_ref === j);
						});

						return isOk && matching;
					}
				};
			};
			newOperator.getApplyFilterFn = newGetApplyFilterFn;
			return newOperator;
		});

	const categoriesFilterOperator = [
		...isAnyofOperator,
		{
			label: "is invalid",
			value: "isEmpty",
			getApplyFilterFn: (filterItem) => {
				// if (!filterItem.field || !filterItem.value || !filterItem.operator) {
				// 	return null;
				// }
				return (value, row, colDef, apiRef) => {
					return value === "unknown";
				};
			},
			requiresFilterValue: false,
		},
	];

	const roleFilterOpetator = [
		...isAnyofOperator,
		{
			label: "is invalid",
			value: "isEmpty",
			getApplyFilterFn: (filterItem) => {
				// if (!filterItem.field || !filterItem.value || !filterItem.operator) {
				// 	return null;
				// }
				return (params) => {
					console.log(params.value);

					return params.value === "unknown";
				};
			},
			requiresFilterValue: false,
		},
	];



			const setSubField=(subFieldName1, subFieldName2, value, row) =>{
							const field = { ...row[subFieldName1] };
							field[subFieldName2] = value;
							const field2 = { ...row };
							field2[subFieldName1] = field;
							return { ...field2 };
						
					}

					const setSubFieldExtraLevel=(subFieldName1, subFieldName2, subFieldName3,value, row) =>{
							const field = { ...row[subFieldName1] };
							field[subFieldName2][subFieldName3] = value;
							const field2 = { ...row };
							field2[subFieldName1] = field;
							return { ...field2 };
					}

	const roles = [
		{
			id: 1,
			text: "No Access",
			userLevel: 0,
			trainingServer: false,
			cashDrawer: 0,
		},

		{
			id: 2,

			text: "Server",
			userLevel: 1,
			trainingServer: false,
			cashDrawer: 1,
		},
		{
			id: 3,

			text: "Supervisor",
			userLevel: 3,
			trainingServer: false,
			cashDrawer: 1,
		},
		{
			id: 4,

			text: "Manager",
			userLevel: 5,
			trainingServer: false,
			cashDrawer: 1,
		},
		{
			id: 5,

			text: "Owner",
			userLevel: 7,
			trainingServer: false,
			cashDrawer: 1,
		},
		{
			id: 6,

			text: "Training Mode",
			userLevel: 1,
			trainingServer: true,
			cashDrawer: 1,
		},
		{
			id: 7,

			text: "Server Drawer 2",
			userLevel: 1,
			trainingServer: false,
			cashDrawer: 2,
		},
		// {
		// 	text: "Engineer",
		// 	"server_details.userLevel": "9",
		// 	"server_details.trainingServer": false,
		// 	"server_details.cashDrawer": 1,
		// },
	];
	const gridList = [
		{
			gridPage: "items", // Navigation Identifier
			gridContext: "items",
			pageTitle: "Item Management", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Item", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			modalEdit: false, //  opens the modal to edit
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "data", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: true,
			showDeleteField: "plu_name",
			defaultvalue: "", // default value
			defaultValues: {
				plus: [
					{
						plu_name: "",
						plu_details: {
							salesGroup: "",
							tags: [],
							price: "",
							priceModifiers: [],
						},
						plu_isDeleted: 0,
						plu_number: "",
						plu_ref: "",
						plu_uid: "",
						ref: "",
						uid: "",
						modifier: "",
						selectedMods: [],
					},
				],
			},
			columns: [
				{
					field: "plu_number",
					headerName: "Ref",
					type: "number",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					visible: true,
					headerAlign: "left",
					align:'left',
					sortComparator: numberSort,
					historyModalVisible: false,
					// resizable: true,
					display: "flex",

					// fieldValidation: /^\d{1,10}$/, // Validation
					// fieldHelper:
					// 	"Must contain numbers, between 1-10 digits long. Check fields for errors.",
				},

				{
					field: "plu_name",
					headerName: "Name",
					type: "text",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					sortable: true,
					visible: true,
					fieldValidation: /^[\w\s',-."" % ()<>!#*/+& ]{0,40}$/, // Validation, characters allowed
					fieldHelper:
						"Must contain letters, numbers, special characters, between 1 and 40 charaters long.",
					fieldDuplicateWarning: true,
					historyModalVisible: true,
					// resizable: true,
					display: "flex",
				},
				{
					field: "plu_details.price",
					masterField: "plu_details",
					headerName: "Price",
					type: "price",
					flex: 0.5,
					defaultvalue: 0, // what is inside modal fields
					editable: true,
					dataField2: "price",
					sortable: true,
					visible: true,
					// resizable: true,
					display: "flex",

					valueGetter: (value, row) => {
						value = row?.plu_details?.price;
						return value;
					},
					valueSetter: (value, row) => setSubField("plu_details", "price", value, row),

					// valueGetter: getSubField,
					// valueSetter: setSubField("plu_details", "price"),
					fieldValidation: /^-?\d{1,6}(\.\d{1,2})?$/,
					fieldHelper:
						"Must be a number with upto 2 decimal places. Check fields for errors.",
					valueFormatter: (value) => {
						// console.log(params.value);
						if (value == null) {
							return "";
						}
						return `${currencyFormatter.format(value)}`;
					},
					renderEditCell: (params) => (
						<PriceInputField
							type="price"
							label={null}
							{...params}
							origin="datagrid"
						/>
					),
					historyModalVisible: true,
				},
				{
					field: "plu_details.salesGroup",
					masterField: "plu_details",
					headerName: "Category",
					type: "singleSelect",
					historyModalVisible: true,
					display: "flex",

					valueOptions: data?.categories?.filter(
						(i) => i?.salesGroup_isDeleted === 0
					),
					getOptionLabel: (value) => value.salesGroup_name,
					getOptionValue: (value) => value.salesGroup_number,
					// valueSetter: setMajorCategory("plu_details", "salesGroup"),
					// valueGetter: (params) => {
					// 	const [fieldName, subFieldName] = params?.field
					// 		?.toString()
					// 		.split(".");
					// 	const field = params?.row[fieldName];
					// 	const val = field[subFieldName];
					// 	const option = params?.colDef?.valueOptions?.find(
					// 		(i) => parseInt(val) === parseInt(i?.salesGroup_number)
					// 	);
					// 	if (option?.salesGroup_number === undefined) {
					// 		return "unknown";
					// 	} else {
					// 		// return data.categories.find(
					// 		// 	(cat) => cat?.salesGroup_number === option?.salesGroup_number
					// 		// );
					// 		return option?.salesGroup_number;
					// 	}
					// },
					valueGetter: (value, row) => {
						if(row?.plu_details?.salesGroup) {
						const option = data?.categories?.find(
							(i) =>
								parseInt(row?.plu_details?.salesGroup) ===
								parseInt(i?.salesGroup_number) && i?.salesGroup_isDeleted === 0
						);
						if (option?.salesGroup_number === undefined) {
							return "unknown";
						} else {
							// return data.categories.find(
							// 	(cat) => cat?.salesGroup_number === option?.salesGroup_number
							// );
							return option?.salesGroup_number;
						}
					} else return null
					},
					valueSetter: (value, row) => setSubField("plu_details", "salesGroup", value, row),

					filterOperators: categoriesFilterOperator,
					getApplyQuickFilterFn: (value) =>
						getApplyFilterFnSalesGroup(value, data),

					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					// align: "center",
					sortable: true,
					visible: true,
					fieldValidation: "",
					renderCell: (params) => {
						if (params.value === "unknown") {
							return (
								<Box>
									<LightTooltip title="This item does not have a valid category assigned">
										<ErrorIcon sx={{ color: Colours.gsblue }} />
									</LightTooltip>
								</Box>
							);
						} else {
							return (
								<Typography variant="datagrid">
									{
										data?.categories?.find(
											(cat) => cat?.salesGroup_number === params.value
										)?.salesGroup_name
									}
								</Typography>
							);
						}
					},
				},
				// {
				// 	field: "majorGroup",
				// 	// masterField: "salesGroup_details",
				// 	headerName: "Major Category",
				// 	type: "majorgroupdisplay",
				// 	flex: 1,
				// 	defaultvalue: "", // what is inside modal fields
				// 	editable: false,
				// 	// dataField2: "salesGroup",
				// 	// dataField: "salesGroup_number", // this is the fiels that is refereced in the lookup to find the value of the field in this data set
				// 	// dataField3: "salesGroup_name",
				// 	// lookupFieldSearch: "majorGroup_number",
				// 	// lookupFieldReturn: "majorGroup_name",
				//     valueGetter: getSubField,
				// 	valueSetter: setSubField("plu_details", "salesGroup"),
				// 	sortable: true,
				// 	visible: false,
				// 	// dataRows: data.categories, // this is an array object from dummy .js file
				// 	fieldValidation: "", // Validation
				// },
				{
					field: "plu_details.tags",
					masterField: "plu_details",
					headerName: "Tags",
					type: "singleSelect",
					flex: 2,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					sortable: true,
					// dataField2: "tags",
					visible: true,
					dataRows: data.tags,
					fieldValidation: "",
					// resizable: true,
					display: "flex",

					sortComparator: tagsSortComparator,
					filterOperators: tagsFilterOperators,
					valueOptions: data?.tags?.filter((i) => i?.tag_isDeleted === 0),
					getOptionLabel: (value) => value.tag_name,
					getOptionValue: (value) => value.tag_ref,
					getApplyQuickFilterFn: (value) => getApplyFilterFnTags(value, data),

					valueGetter: (value, row) => {
						// if (value !== undefined) {
						const arr = [];
						row?.plu_details?.tags?.map((tagNumber) => {
							tagNumber = data?.tags?.filter(
								(newTag) =>
									newTag?.tag_ref === tagNumber && newTag.tag_isDeleted === 0
							);
							if (tagNumber[0] !== undefined) {
								arr.push(tagNumber[0]);
							}
						});
						return arr;
					
					},
				
					valueSetter: (value, row) => setSubField("plu_details", "tags", value, row),

				
					valueFormatter: (value) => {
						let arr = [];
						value?.map((value) => {
							arr.push(value?.tag_name);

							return arr;
						});
						let str = arr.toString();
						let replaceStr = str.replaceAll(",", "; ");
						return replaceStr;
					},
					renderEditCell: (params) => (
						<AutocompleteChipCell
							{...params}
							// ref={ref}
							type="tag"
							options={data?.tags?.filter((i) => i?.tag_isDeleted === 0)}
						/>
					),
					renderCell: (params, item) => <ExpandingTagCell value={params?.value} width={params.colDef.computedWidth} />,

					historyModalVisible: true,
				},
			
				{
					field: "plu_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					// resizable: true,
					display: "flex",
					// getApplyQuickFilterFn: deletedQuickFilterFn,
					filterable:
						// checkPermissions(userPermission, {
						// 	page: "tags",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "items",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "items",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (row.plu_isDeleted === 1 || row.plu_isDeleted === "1") {
							value = true;
						} else if (row.plu_isDeleted === 0 || row.plu_isDeleted === "0") {
							value = false;
						}

						return value;
					},
					historyModalVisible: false,
				},
			],
		},
		{
			gridPage: "majorcategories", // Navigation Identifier
			gridContext: "majorcategories",
			pageTitle: "Major Categories", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Major Category", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check box delete button
			modalEdit: false,
			// dataRows: majorGroups, // pull in dummy data
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "dataRows", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "majorGroup_name",
			defaultValues: {
				majorGroup_name: "",
				majorGroup_details: {
					tags: [],
				},
			},
			// columns what define the grid and columns, this alos has an impact on th modal filds
			columns: [
				{
					field: "majorGroup_number",
					headerName: "Number",
					type: "number",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					visible: true,
					display: "flex",
headerAlign:'left',
align:'left',
					sortComparator: numberSort,
					fieldValidation: /^\d{0,10}$/, // Validation
					fieldHelper:
						"Must contain numbers that must be between 1 to 10 digits long. Check fields for errors.",
					historyModalVisible: false,
				},

				{
					field: "majorGroup_name",
					headerName: "Name",
					type: "text",
					flex: 1,
					defaultvalue: "",
					display: "flex",

					editable: true,
					sortable: true,
					visible: true,
					fieldValidation: /^[a-zA-Z0-9',-.""()* ]{0,64}$/, // Validation, // Validation
					fieldDuplicateWarning: true,
					fieldHelper:
						"Must contain letters, numbers, special characters, between 2 and 64 charaters long.",
					historyModalVisible: true,
				},

				{
					field: "majorGroup_details.tags",
					masterField: "majorGroup_details",
					headerName: "Tags",
					type: "singleSelect",
					flex: 2,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					sortable: true,
					// dataField2: "tags",
					visible: true,
					display: "flex",

					dataRows: data.tags,
					fieldValidation: "",
					sortComparator: tagsSortComparator,
					filterOperators: tagsFilterOperators,
					valueOptions: data?.tags?.filter((i) => i?.tag_isDeleted === 0),
					getOptionLabel: (value) => value.tag_name,
					getOptionValue: (value) => value.tag_ref,
					getApplyQuickFilterFn: (value) => getApplyFilterFnTags(value, data),

					historyModalVisible: false,

					valueGetter: (value, row) => {
						// if (value !== undefined) {
						const arr = [];
						row?.majorGroup_details?.tags?.map((tagNumber) => {
							tagNumber = data?.tags?.filter(
								(newTag) =>
									newTag?.tag_ref === tagNumber && newTag.tag_isDeleted === 0
							);
							if (tagNumber[0] !== undefined) {
								arr.push(tagNumber[0]);
							}
						});
						// console.log(arr);
						return arr;
						// } else return [];
						// console.log(userLevel);
					},
					
					valueSetter: (value, row) => setSubField("majorGroup_details", "tags", value, row),
					valueFormatter: (value) => {
						let arr = [];
						value?.map((value) => {
							arr.push(value?.tag_name);

							return arr;
						});
						let str = arr.toString();
						let replaceStr = str.replaceAll(",", "; ");
						return replaceStr;
					},
					renderEditCell: (params) => (
						<AutocompleteChipCell
							{...params}
							// ref={ref}
							type="tag"
							options={data?.tags?.filter((i) => i?.tag_isDeleted === 0)}
						/>
					),
					renderCell: (params, item) => <ExpandingTagCell value={params?.value} width={params.colDef.computedWidth} />,

				},
				// {
				// 	field: "tagExport",
				// 	masterField: "majorGroup_details",
				// 	headerName: "Tag Export",
				// 	type: "tagExport",
				// 	flex: 3,
				// 	defaultvalue: "", // what is inside modal fields
				// 	editable: false,
				// 	sortable: true,
				// 	dataField2: "tags",
				// 	visible: false,
				// 	dataField: "tag_name",
				// 	dataField3: "tag_name",
				// 	dataRows: data.tags, // this is an array object from dummy .js file
				// 	fieldValidation: "",
				// 	valueGetter: (params) => getTagValueNewGrid(params, data),

				// 	valueFormatter: ({ value }) => {
				// 		console.log(value);
				// 		let arr = [];
				// 		value?.map((value) => {
				// 			let tag = data.tags?.filter((tag) => tag?.tag_ref === value);
				// 			if (tag[0] !== undefined) {
				// 				arr.push(tag[0]?.tag_name);
				// 			}
				// 			return arr;
				// 		});
				// 		let str = arr.toString();
				// 		let replaceStr = str.replaceAll(",", "; ");
				// 		return replaceStr;
				// 	},
				// },
				{
					field: "majorGroup_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					display: "flex",

					historyModalVisible: false,

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "majorCategory",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					allowToggle: false,
					visible:
						checkPermissions(userPermission, {
							page: "majorCategory",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (
							row?.majorGroup_isDeleted === 1 ||
							row?.majorGroup_isDeleted === "1"
						) {
							value = true;
						} else if (
							row?.majorGroup_isDeleted === 0 ||
							row?.majorGroup_isDeleted === "0"
						) {
							value = false;
						}

						return value;
					},
				},
			],
		},
		{
			gridPage: "categories", // Navigation Identifier $$$$$ NOW CATEGORY
			gridContext: "categories", // Navigation Context
			pageTitle: "Categories", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Category", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check box delete button
			// dataRows: salesGroups, // pull in dummy data
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "dataRows", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "salesGroup_name",
			// allowNewCopy: false, // this is if we want the switch on a modal for copying data like mdifier plus

			// newCopyLookup: data?.categories?.map((option) => ({
			// 	value: option.salesGroup_number,
			// 	text: option.salesGroup_name,
			// })),
			// newCopyLookupField: "salesGroup_number",
			defaultValues: {
				salesGroup_name: "",
				salesGroup_details: {
					tags: [],
					majorGroup: "",
				},
				salesGroup_isDeleted: 0,
			},
			columns: [
				{
					field: "salesGroup_number",
					headerName: "Number",
					type: "number",
					flex: 1,
					defaultvalue: "",
					editable: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
					sortable: true,
					visible: true,
					sortComparator: numberSort,
					historyModalVisible: false,

					fieldValidation: /^\d{0,10}$/, // Validation
					fieldHelper:
						"Must contain numbers that must be between 1 to 10 digits long. Check fields for errors.",
				},

				{
					field: "salesGroup_name",
					headerName: "Name",
					type: "text",
					flex: 1,
					defaultvalue: "",
					editable: true,
					sortable: true,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					fieldValidation: /^[a-zA-Z0-9',-."" % ()<>!#*/+& ]{0,64}$/, // Validation, // Validation
					fieldDuplicateWarning: true,
					fieldHelper:
						"Must contain letters, numbers, special characters, between 1 and 40 charaters long.",
				},
				{
					field: "salesGroup_details.majorGroup",
					// masterField: "salesGroup_details",
					headerName: "Major Category",
					type: "singleSelect",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					display: "flex",

					// dataField2: "majorGroup",
					// dataField: "majorGroup_number",
					// dataField3: "majorGroup_name",
					// lookupFieldSearch: "majorGroup_number",
					// lookupFieldReturn: "majorGroup_name",
					sortable: true,
					visible: true,
					valueOptions: data?.majorcategories?.filter(
						(i) => i?.majorGroup_isDeleted === 0
					), // this is an array object from dummy .js file
					fieldValidation: "", // Validation
					historyModalVisible: true,

					getOptionLabel: (value) => (value ? value.majorGroup_name : ""),
					getOptionValue: (value) => (value ? value.majorGroup_number : ""),
					valueSetter: (value, row) => setSubField("salesGroup_details", "majorGroup", value, row),

					valueGetter: (value, row) => {
						// const [fieldName, subFieldName] = params?.field
						// 	?.toString()
						// 	.split(".");
						// const field = row[fieldName];
						const val = row?.salesGroup_details?.majorGroup;
						const option = data.majorcategories
							.filter((i) => i.majorGroup_isDeleted === 0)
							.find((i) => parseInt(val) === parseInt(i?.majorGroup_number));
						// console.log(option);
						return option?.majorGroup_number;
					},
				},
				{
					field: "salesGroup_details.tags",
					masterField: "salesGroup_details",
					headerName: "Tags",
					type: "singleSelect",
					flex: 2,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					sortable: true,
					display: "flex",

					// dataField2: "tags",
					visible: true,
					historyModalVisible: false,

					dataRows: data.tags,
					fieldValidation: "",
					sortComparator: tagsSortComparator,
					filterOperators: tagsFilterOperators,
					valueOptions: data?.tags?.filter((i) => i.tag_isDeleted === 0),
					getOptionLabel: (value) => value.tag_name,
					getOptionValue: (value) => value.tag_ref,
					getApplyQuickFilterFn: (value) => getApplyFilterFnTags(value, data),

					valueGetter: (value, row) => {
						// if (value !== undefined) {
						const arr = [];
						row?.salesGroup_details?.tags?.map((tagNumber) => {
							tagNumber = data?.tags?.filter(
								(newTag) =>
									newTag?.tag_ref === tagNumber && newTag.tag_isDeleted === 0
							);
							if (tagNumber[0] !== undefined) {
								arr.push(tagNumber[0]);
							}
						});
						// console.log(arr);
						return arr;
						// } else return [];
						// console.log(userLevel);
					},
					valueSetter: (value, row) => setSubField("salesGroup_details", "tags", value, row),

					// valueSetter: setSubField("plu_details", "tags"),
					valueFormatter: (value) => {
						let arr = [];
						value?.map((value) => {
							arr.push(value?.tag_name);

							return arr;
						});
						let str = arr.toString();
						let replaceStr = str.replaceAll(",", "; ");
						return replaceStr;
					},
					renderEditCell: (params) => (
						<AutocompleteChipCell
							{...params}
							// ref={ref}
							type="tag"
							options={data?.tags.filter((i) => i.tag_isDeleted === 0)}
						/>
					),
					renderCell: (params, item) => <ExpandingTagCell value={params?.value} width={params.colDef.computedWidth} />,

				},
				
				// {
				// 	field: "tagExport",
				// 	masterField: "salesGroup_details",
				// 	headerName: "Tag Export",
				// 	type: "tagExport",
				// 	flex: 3,
				// 	defaultvalue: "", // what is inside modal fields
				// 	editable: false,
				// 	sortable: false,
				// 	dataField2: "tags",
				// 	visible: false,
				// 	dataField: "tag_name",
				// 	dataField3: "tag_name",
				// 	dataRows: data.tags, // this is an array object from dummy .js file
				// 	fieldValidation: "",
				// 	disableColumnMenu: true,
				// },
				{
					field: "salesGroup_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "categories",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					allowToggle: false,
					visible:
						checkPermissions(userPermission, {
							page: "categories",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
				},
			],
			valueGetter: (value, row) => {
				if (
					row?.salesGroup_isDeleted === 1 ||
					row?.salesGroup_isDeleted === "1"
				) {
					value = true;
				} else if (
					row?.salesGroup_isDeleted === 0 ||
					row?.salesGroup_isDeleted === "0"
				) {
					value = false;
				}

				return value;
			},
		},
		{
			gridPage: "staff", // Navigation Identifier
			gridContext: "staff", // Navigation Context
			pageTitle: "User", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Staff Member", //Edit Modal title
			modalEdit: false, //  opens the modal to edit
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check box delete button
			dataRows: data.staff, // pull in dummy data
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "dataRows", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "server_name",
			defaultValues: {
				server_name: "",
				server_details: {
					displayName: "",
					tags: [],
					userLevel: "",
					trainingServer: "",
					cashDrawer: "",
					pin: "",
				},
				server_isDeleted: 0,
				server_number: "",
			},
			// columns what define the grid and columns, this alos has an impact on th modal filds
			columns: [
				{
					field: "server_ref",
					headerName: "Ref",
					type: "id",
					flex: 1,
					defaultvalue: "",
					editable: false,
					sortable: true,
					visible: false,
					historyModalVisible: false,
					display: "flex",

					// fieldValidation: /^\d{1,10}$/, // Validation
					// fieldHelper:
					// 	"Must contain numbers that must be between 1 to 10 digits long. Check fields for errors.",
				},

				{
					field: "server_number",
					headerName: "Number",
					type: "number",
					flex: 0.5,
					defaultvalue: "",
					editable: true,
					sortable: true,
					visible: true,
					historyModalVisible: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
					sortComparator: numberSort,
					fieldValidation: /^(?!419$)\d{0,4}$/, // Validation
					fieldHelper:
						"Must contain numbers that must be between 1 to 4 digits long. Check fields for errors.",
					fieldDuplicateWarning: true, // this shows a validation warning if the field content matches regardless of case-senseitive
					fieldDuplicateError: true, // this shows a validation warning if the field content matches regardless of case-senseitive
				},
				{
					field: "server_name",
					headerName: "Name",
					type: "text",
					flex: 1,
					defaultvalue: "",
					editable: true,
					sortable: true,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					fieldValidation: /^[a-zA-Z0-9',-."" % ()<>!#*/+& ]{0,64}$/, // Validation, // Validation
					fieldHelper:
						"Must contain letters, numbers, special characters, between 1 and 64 charaters long.",
				},
				{
					field: "server_details.displayName",
					masterField: "server_details",
					// dataField: "displayName",
					// dataField3: "level_name",
					headerName: "Display Name",
					type: "text",
					flex: 1,
					defaultvalue: "",
					historyModalVisible: true,
					display: "flex",

					editable: true,
					// dataField2: "displayName",
					sortable: true,
					visible: true,
					valueGetter: (value, row) => {
						value = row?.server_details?.displayName;
						return value;
					},
					valueSetter: (value, row) => setSubField("server_details", "displayName", value, row),

					fieldValidation: /^[a-zA-Z0-9',-."" % ()<>!#*/+& ]{0,64}$/, // Validation, // Validation
					fieldHelper:
						"Must contain letters, numbers, special characters, between 1 and 64 charaters long.",
				},
				{
					field: "server_details.userLevel",
					masterField: "server_details",
					// dataField: "userLevel",
					headerName: "Role",
					type: "singleSelect",
					flex: 1,
					defaultvalue: "",
					editable: true,
					sortable: true,
					historyModalVisible: true,
					display: "flex",

					visible: true,
					valueGetter: (value, row) => {
						const userLevel = roles.find(
							(i) =>
								parseInt(row?.server_details?.userLevel) ===
									parseInt(i?.userLevel) &&
								row?.server_details?.cashDrawer === i?.cashDrawer &&
								row?.server_details?.trainingServer === i?.trainingServer
						);
						// console.log(userLevel);
						if (userLevel) {
							return userLevel?.id;
						} else {
							return null;
						}
						
					},

					getOptionLabel: (value) => (value ? value?.text : ""),
					getOptionValue: (value) => (value ? value?.id : ""),

					valueSetter: (value, row) => {
						// const field = { ...params?.row["server_details"] };

						const role = roles?.find((r) => {
							return parseInt(r?.id) === parseInt(value);
						});

						if (role) {
		
							const field = { ...row['server_details'] };
							field['userLevel'] = role?.userLevel;
							field["cashDrawer"] = role?.cashDrawer;
							field["trainingServer"] = role?.trainingServer;
							const field2 = { ...row };
							field2['server_details'] = field;
							console.log(field2);
							return { ...field2 };
						}  else {
							return row;
						}

						// return { ...field2 };
					},
					
			
					valueOptions: roles,
				},
				{
					field: "server_details.pin",
					masterField: "server_details",
					dataField: "pin",
					dataField3: "level_name",
					headerName: "Pin",
					type: "text",
					flex: 1,
					defaultvalue: "",
					editable: true,
					dataField2: "pin",
					filterable: false,
					historyModalVisible: false,
					display: "flex",

					visible: true,
					passwordMask: true,
					valueGetter: (value, row) => {
							if(row?.server_details?.pin) {

						value = row?.server_details?.pin ? row?.server_details?.pin : '';
						return value;
							} else return null
					},
					valueSetter: (value, row) => setSubField("server_details", "pin", value, row),


					renderCell: (params) => params?.value  && <>****</>,
					renderEditCell: (params) => (
					
						<PasswordField {...params} />
					),
					// valueFormatter: ({ value }) => {
					// 	return "****";
					// },
					// fieldValidation: /^\d{1,4}$/, // Validation
					fieldValidation: /^(?!419$)\d{0,4}$/, // Validation
					fieldHelper:
						"Must contain numbers that must be between 1 to 4 digits long. Check fields for errors.",
				},
				
				{
					field: "server_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "staff",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					allowToggle: false,
					visible:
						checkPermissions(userPermission, {
							page: "staff",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
				},
			],
			valueGetter: (value, row) => {
				if (row?.server_isDeleted === 1 || row?.server_isDeleted === "1") {
					value = true;
				} else if (row?.server_isDeleted === 0 || row?.server_isDeleted === "0") {
					value = false;
				}

				return value;
			},
		},

		{
			gridPage: "sites", // Navigation Identifier
			gridContext: "sites",
			pageTitle: "Site Management", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: false, // Display modal option
			modalTitle: "Site", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			modalEdit: false, //  opens the modal to edit
			//dataRows: sites, // pull in dummy data
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "dataRows", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: true,
			showDeleteField: "site_name",
			defaultValues: {
				site_name: "",
				site_details: { pricebands: [], tags: [] },
			},
			columns: [
				{
					field: "site_number",
					headerName: "Number",
					type: "text",
					flex: 0.5,
					defaultvalue: "",
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",
headerAlign:'left',
align:'left',
					visible: true,
					sortComparator: numberSort,
					fieldValidation: /^\d{0,10}$/, // Validation
					fieldHelper:
						"Must contain numbers that must be between 1 to 10 digits long. Check fields for errors.",
				},
				{
					field: "site_ref",
					headerName: "Ref",
					type: "text",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					sortable: true,
					visible: false,
					historyModalVisible: false,
					display: "flex",

					fieldValidation: /^\d{0,10}$/, // Validation
					fieldHelper:
						"Must contain numbers that can be 1 to 10 digits long. Check fields for errors.",
				},

				{
					field: "site_name",
					headerName: "Name",
					type: "text",
					flex: 1,
					defaultvalue: "",
					editable: false,
					sortable: true,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					fieldValidation: /^[a-zA-Z0-9',-."" % ()<>!#*/+& ]{0,64}$/, // Validation, // Validation
					fieldHelper:
						"Must contain letters, numbers, special characters, between 1 and 64 charaters long.",
				},
				{
					field: "site_details.pricebands",
					masterField: "site_details",
					headerName: "Price Bands",
					type: "singleSelect",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					historyModalVisible: true,
					display: "flex",
					sortable: true,
					visible: true,

					valueSetter: (value, row) => setSubField("site_details", "pricebands", value, row),

					valueGetter: (value, row) => {
						const arr = [];
						if (row?.site_details?.pricebands) {
							if (Array.isArray(row?.site_details?.pricebands)) {
								row?.site_details?.pricebands?.map((x) => {
									let obj = data?.priceband
										?.filter((i) => i?.priceband_isDeleted === 0)
										.find((i) => parseInt(i["priceband_ref"]) === parseInt(x));
									if (row?.site_details?.pricebands !== undefined) {
										arr.push(obj);
									}
								});
								return arr;
							} else
								return data?.find(
									(i) =>
										parseInt(i["priceband_ref"]) ===
										parseInt(row?.site_details?.pricebands)
								);
						}
						return arr;
					},

					filterOperators: pricebandOperator,
					getApplyQuickFilterFn: (value) =>
						getApplyFilterFnPriceBand(value, data?.priceband),
					valueOptions: data?.priceband?.filter(
						(i) => i?.priceband_isDeleted === 0
					),
					getOptionLabel: (value) => value?.priceband_name,
					getOptionValue: (value) => value?.priceband_ref,
					renderEditCell: (params) => {
						return (
							<AutocompleteChipCell
								{...params}
								type="priceband"
								options={data?.priceband?.filter(
									(i) => i?.priceband_isDeleted === 0
								)}
							/>
						);
					},
					renderCell: (params, item) => (
						<ChipDisplayCell type="priceband" {...params} />
					),
					valueFormatter: ({ value }) => {
						let arr = [];
						value?.map((value) => {
						
							arr.push(value?.priceband_name);
							
							return arr;
						});
						let str = arr.toString();
						let replaceStr = str.replaceAll(",", "; ");
						return replaceStr;
					},
				},

				{
					field: "site_details.tags",
					masterField: "site_details",
					headerName: "Tags",
					type: "singleSelect",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					sortable: true,
					dataField2: "tags",
					visible: true,
					historyModalVisible: true,
					display: "flex",

					dataRows: data.tags, // this is an array object from dummy .js file
					fieldValidation: "",
					sortComparator: tagsSortComparator,
					filterOperators: tagsFilterOperators,
					valueOptions: data?.tags?.filter((i) => i?.tag_isDeleted === 0),
					getOptionLabel: (value) => value.tag_name,
					getOptionValue: (value) => value.tag_ref,
					getApplyQuickFilterFn: (value) => getApplyFilterFnTags(value, data),
					valueGetter: (value, row) => {
						const arr = [];
						row?.site_details?.tags?.map((tagNumber) => {
							tagNumber = data?.tags?.filter(
								(newTag) =>
									newTag?.tag_ref === tagNumber && newTag.tag_isDeleted === 0
							);
							if (tagNumber[0] !== undefined) {
								arr.push(tagNumber[0]);
							}
						});
						return arr;
						
					},
					valueSetter: (value, row) => setSubField("site_details", "tags", value, row),

					valueFormatter: (value) => {
						let arr = [];
						value?.map((value) => {
							arr.push(value?.tag_name);

							return arr;
						});
						let str = arr.toString();
						let replaceStr = str.replaceAll(",", "; ");
						return replaceStr;
					},

					renderEditCell: (params) => (
						<AutocompleteChipCell
							{...params}
							type="tag"
							options={data?.tags?.filter((i) => i?.tag_isDeleted === 0)}
						/>
					),
					renderCell: (params, item) => <ExpandingTagCell value={params?.value} width={params.colDef.computedWidth} />,

				},
				// {
				// 	field: "tagExport",
				// 	masterField: "site_details",
				// 	headerName: "Tag Export",
				// 	type: "tagExport",
				// 	flex: 3,
				// 	defaultvalue: "", // what is inside modal fields
				// 	editable: false,
				// 	sortable: true,
				// 	dataField2: "tags",
				// 	visible: false,
				// 	dataField: "tag_name",
				// 	dataField3: "tag_name",
				// 	dataRows: data.tags, // this is an array object from dummy .js file
				// 	fieldValidation: "",
				// },
				{
					field: "site_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "tags",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "sites",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "sites",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (row?.site_isDeleted === 1 || row?.site_isDeleted === "1") {
							value = true;
						} else if (row?.site_isDeleted === 0 || row?.site_isDeleted === "0") {
							value = false;
						}

						return value;
					},
				},
			],
		},
		{
			gridPage: "printers", // Navigation Identifier
			gridContext: "printers",
			pageTitle: "Printer Management", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Printers", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			modalEdit: true, //  opens the modal to edit
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "dataRows", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "printer_name",
			defaultValues: {
				printer_name: "",
				printer_details: {
					sites: {},
				},
				printer_isDeleted: 0,
			},

			columns: [
				// {
				// 	field: "printer_ref",
				// 	headerName: "Ref",
				// 	type: "text",
				// 	flex: 1,
				// 	defaultvalue: "", // what is inside modal fields
				// 	editable: false,
				// 	sortable: true,
				// 	visible: false,
				// 	// fieldValidation: /^[a-zA-Z0-9',-.""() ]{1,64}$/, // Validation, // Validation
				// 	// fieldHelper:
				// 	// 	"Must contain letters, numbers, special characters, between 1 and 64 charaters long.",
				// },

				{
					field: "printer_number",
					headerName: "Number",
					type: "text",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					visible: false,
					historyModalVisible: false,
					display: "flex",

					sortComparator: numberSort,
					// fieldValidation: /^[a-zA-Z0-9',-.""() ]{1,64}$/, // Validation, // Validation
					// fieldHelper:
					// 	"Must contain letters, numbers, special characters, between 1 and 64 charaters long.",
				},

				{
					field: "printer_name",
					headerName: "Name",
					type: "text",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					sortable: true,
					historyModalVisible: true,
					display: "flex",

					visible: true,
					fieldValidation: /^[a-zA-Z0-9',-."" % ()<>!#*/+& ]{0,64}$/, // Validation, // Validation
					fieldHelper:
						"Must contain letters, numbers, special characters, between 1 and 64 charaters long.",
				},
				{
					field: "printer_details.site_name",
					masterField: "printer_details",
					headerName: "Site Name",
					type: "text", // where can change a key and its value
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					sortable: true,
					dataField2: "sites",
					disableExport: true,
					historyModalVisible: false,
					display: "flex",

					visible: true,
					fieldValidation: "",
					valueGetter: (value, row) => {
						const arr = [];
						if (row?.printer_details?.sites) {
							Object?.keys(row?.printer_details?.sites).map((v) =>
								arr.push(
									data.sites.find((s) => parseInt(s?.site_ref) === parseInt(v))
										?.site_name
								)
							);
						}
						return arr;
					},

					renderCell: (params) => (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								height: "100%",
								m: 0,
								p: 0,
								width: "100%",
								// borderBottom: "1px solid grey",
							}}
						>
							{params?.value && typeof params?.value === "object" ? (
								params?.value?.map((key) => (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											width: "100%",
											height: "100%",
											p: 1,
											borderBottom:
												key !== params?.value?.at(-1) && "1px solid #f0f0f0",
										}}
										key={key}
									>
										<Typography sx={{ fontSize: 13 }}>{key}</Typography>
									</Box>
								))
							) : (
								<div>{params?.value}</div>
							)}
						</Box>
					),
				},
				{
					field: "printer_details.site_ref",
					masterField: "printer_details",
					headerName: "Site Ref",
					type: "text", 
					flex: 1,
					defaultvalue: "", 
					editable: true,
					sortable: true,
					dataField2: "sites",
					disableExport: true,
					historyModalVisible: true,
					display: "flex",

				
					visible: true,
					fieldValidation: "",
					valueGetter: (value, row) => {
						if (row?.printer_details?.sites) {
							return Object?.keys(row?.printer_details?.sites);
						}
					},

					renderCell: (params) => (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								height: "100%",
								m: 0,
								p: 0,
								width: "100%",
								// borderBottom: "1px solid grey",
							}}
						>
							{params?.value && typeof params?.value === "object" ? (
								params?.value?.map((key) => (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											width: "100%",
											height: "100%",
											p: 1,
											borderBottom:
												key !== params.value.at(-1) && "1px solid #f0f0f0",
										}}
										key={key}
									>
										<Typography sx={{ fontSize: 13 }}>{key}</Typography>
									</Box>
								))
							) : (
								<div>{params?.value}</div>
							)}
						</Box>
					),
				},
				{
					field: "printer_details.sites",
					masterField: "printer_details",
					headerName: "Printer Numbers",
					type: "text", 
					flex: 1,
					defaultvalue: "", 
					editable: true,
					sortable: true,
					dataField2: "sites",
					JSONkeyHeaderName: "Site",
					JSONvalueHeaderName: "Printer Number",
					JSONvar1HeaderName: "Site Ref",
					JSONvar2HeaderName: "Site Name",
					JSONvar3HeaderName: "Printer Number",
					JSONkeyType: "list",
					JSONkeyOptions: data?.sites
						?.filter((i) => i.site_isDeleted === 0)
						.map((row) => ({
							value: row?.site_ref,
							text: row?.site_name,
						})),
					JSONShowKeyLookUp: true,
					JSONvalueType: "multiList",
					JSONvalueDefault: [],
					JSONvalueOptions: [
						{ value: 1, text: "Printer 1" },
						{ value: 2, text: "Printer 2" },
						{ value: 3, text: "Printer 3" },
						{ value: 4, text: "Printer 4" },
						{ value: 5, text: "Printer 5" },
						{ value: 6, text: "Printer 6" },
						{ value: 7, text: "Printer 7" },
						{ value: 8, text: "Printer 8" },
						{ value: 9, text: "Printer 9" },
						{ value: 10, text: "Printer 10" },
						{ value: 11, text: "Printer 11" },
						{ value: 12, text: "Printer 12" },
						{ value: 13, text: "Printer 13" },
						{ value: 14, text: "Printer 14" },
						{ value: 15, text: "Printer 15" },
						{ value: 16, text: "Printer 16" },
					],
					visible: true,
					disableExport: true,
					historyModalVisible: true,
					display: "flex",

					fieldValidation: "",
					valueGetter: (value, row) => {

						if (row?.printer_details?.sites) {
							const newVal = Object?.entries(row?.printer_details?.sites)?.map(
								(i) => {
									return {
									
										Printers: i[1],
										Site: i[0],
									};
								}
							);
							if (newVal) {
								row["modalValues"] = newVal;
								// console.log(newVal);
								return newVal;
							}
						} else return null;
					},
					
					renderCell: (params) => {
						return (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									height: "100%",
									m: 0,
									p: 0,
									width: "100%",
									// borderBottom: "1px solid grey",
								}}
								key={params?.value}
							>
								
								{/* {params.value && typeof params.value === "object" ? ( */}
								{params?.value?.map((val, i) => (
									//These need to be divs not typograpys as affects the format due to cplex nesting of JSON values
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											width: "max-content",
											height: "100%",
											// p: 1,
											borderBottom:
												val !== params?.value?.at(-1) && "1px solid #f0f0f0",
										}}
										key={i}
									>
										{val?.Printers?.map((v, i) => (
											<StyledTag
												colour={Colours.gsblue}
												key={i}
												label={v}
												square={"true"}
											/>
										))}
									</Box>
								))}
							
							</Box>
						);
					},
				},

				{
					field: "printer_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 0.5,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "printers",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "printers",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "printers",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (row?.printer_isDeleted === 1 || row?.printer_isDeleted === "1") {
							value = true;
						} else if (
							row?.printer_isDeleted === 0 ||
							row?.printer_isDeleted === "0"
						) {
							value = false;
						}

						return value;
					},
				},
			],
		},

		{
			gridPage: "pricing", // Navigation Identifier
			pageTitle: "Price Management", // Page Title
			// gridContext: "items",
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: false, // Display modal option (this now works as added back in to)
			modalTitle: "Price", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "dataRows", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "plu_name",

			columns: [
				{
					field: "plu_number",
					headerName: "Ref",
					type: "number",
					flex: 1,
					minWidth: 100,
					defaultvalue: "",
					editable: false,
					sortable: true,
					visible: true,
					headerAlign: "left",
					sortComparator: numberSort,
					historyModalVisible: false,
					display: "flex",
align:'left'
				},

				{
					display: "flex",
					field: "plu_name",
					headerName: "Name",
					type: "text",
					flex: 2,
					minWidth: 130,
					defaultvalue: "", 
					editable: true,
					sortable: true,
					visible: true,
					fieldValidation: /^[\w\s',-."" % ()<>!#*/+& ]{0,40}$/, // Validation, characters allowed
					fieldHelper:
						"Must contain letters, numbers, special characters, between 1 and 40 charaters long.",
					fieldDuplicateWarning: true,
				},
				{
					field: "plu_details.price",
					masterField: "plu_details",
					headerName: "Price",
					type: "price",
					flex: 1,
					minWidth: 70,
					defaultvalue: 0, 
					editable: true,
					dataField2: "price",
					sortable: true,
					visible: true,
					display: "flex",

					valueGetter: (value, row) => {
						value = row?.plu_details?.price;
						return value;
					},
					valueSetter: (value, row) => setSubField("plu_details", "price", value, row),

					fieldValidation: /^-?\d{1,6}(\.\d{1,2})?$/,
					fieldHelper:
						"Must be a number with upto 2 decimal places. Check fields for errors.",
					valueFormatter: (value) => {
						// console.log(params.value);
						if (value == null) {
							return "";
						}
						return `${currencyFormatter.format(value)}`;
					},
					renderEditCell: (params) => (
						<PriceInputField
							type="price"
							label={null}
							{...params}
							origin="datagrid"
						/>
					),
				},
				{
					field: "plu_details.price_update",
					masterField: "plu_details",
					headerName: "Price",
					type: "price",
					flex: 1,
					minWidth: 70,
					defaultvalue: 0, 
					editable: true,
					dataField2: "price",
					sortable: true,
					visible: false,
					display: "flex",

					valueGetter: (value, row) => {
						value = row?.plu_details?.price_update;
						return value;
					},
					valueSetter: (value, row) => setSubField("plu_details", "price", value, row),

					fieldValidation: /^-?\d{1,6}(\.\d{1,2})?$/,
					fieldHelper:
						"Must be a number with upto 2 decimal places. Check fields for errors.",
					valueFormatter: (value) => {
						// console.log(params.value);
						if (value == null) {
							return "";
						}
						return `${currencyFormatter.format(value)}`;
					},
					renderEditCell: (params) => (
						<PriceInputField
							type="price"
							label={null}
							{...params}
							origin="datagrid"
						/>
					),
				},
				{
					field: "plu_details.salesGroup",
					masterField: "plu_details",
					headerName: "Category",
					type: "singleSelect",
					valueOptions: data?.categories?.filter(
						(i) => i?.salesGroup_isDeleted === 0
					),
					getOptionLabel: (value) => value?.salesGroup_name,
					getOptionValue: (value) => value?.salesGroup_number,
					valueSetter: (value, row) => setSubField("plu_details", "salesGroup", value, row),

					valueGetter: (value, row) => {
					
						const val = row?.plu_details?.salesGroup;
						const option = data.categories
							.filter((i) => i?.salesGroup_isDeleted === 0)
							.find((i) => parseInt(val) === parseInt(i?.salesGroup_number));
						if (option?.salesGroup_number === undefined) {
							return "unknown";
						} else {
						
							return option?.salesGroup_number;
						}
					},
					filterOperators: categoriesFilterOperator,
					getApplyQuickFilterFn: (params) =>
						getApplyFilterFnSalesGroup(params, data),

					flex: 1,
					minWidth: 120,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					// align: "center",
					display: "flex",

					sortable: true,
					visible: true,
					fieldValidation: "",
					renderCell: (params) => {
						if (params?.value === "unknown") {
							return (
								<Box>
									<LightTooltip title="This item does not have a valid category assigned">
										<ErrorIcon sx={{ color: Colours.gsblue }} />
									</LightTooltip>
								</Box>
							);
						} else {
							return (
								<Typography variant="datagrid">
									{
										data?.categories?.find(
											(cat) => cat?.salesGroup_number === params.value
										)?.salesGroup_name
									}
								</Typography>
							);
						}
					},
				},
				
				{
					field: "plu_details.priceModifiers",
					masterField: "plu_details",
					headerName: "Price Modifiers",
					type: "priceModifier",
					flex: 1,
					defaultvalue: 0, // what is inside modal fields
					editable: true,
					dataField2: "priceModifiers",
					historyModalVisible: false,

					sortable: true,
					visible: true,
					display: "flex",
					valueFormatter: (value) => currencyFormatter.format(value),
					renderCell: ({ value }) => `£` + value,
				},
				{
					field: "plu_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					display: "flex",
					width: 30,
					filterable:
						// checkPermissions(userPermission, {
						// 	page: "tags",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "items",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible: false,
					// checkPermissions(userPermission, {
					// 	page: "items",
					// 	// allow: true,
					// 	action: "undelete",
					// 	strict: true,
					// }) === true,
					valueGetter: (value, row) => {
						if (row?.plu_isDeleted === 1 || row?.plu_isDeleted === "1") {
							value = true;
						} else if (row?.plu_isDeleted === 0 || row?.plu_isDeleted === "0") {
							value = false;
						}

						return value;
					},
					historyModalVisible: false,
				},
			],
		},
		{
			gridPage: "tags", // Navigation Identifier
			gridContext: "tags",
			pageTitle: "Tag Management", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Tag", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			modalEdit: true, // the default is true - false opens the modal to edit
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			rowEditingField: "tag_details.isEditable", // this is use to toggle edit depending on the value of field in the row
			//dataRows: data.tags, // pull in dummy data
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "dataRows", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "tag_name",
			defaultValues: {
				tag_name: "",
				tag_details: {
					isEditable: true,
					styling: { colour: "#94011d" },
					modules: [],
				},
			},

			columns: [
				// {
				// 	field: "uid",
				// 	headerName: "UID",
				// 	type: "id",
				// 	flex: 1,
				// 	defaultvalue: "", // what is inside modal fields
				// 	editable: true,
				// 	sortable: true,
				// 	visible: false,
				// 	display: "flex",

				// 	historyModalVisible: false,

				// 	// fieldValidation: /^[a-zA-Z0-9',-.""() ]{2,64}$/, // Validation, // Validation
				// 	// fieldHelper: "Must contain letters, numbers, special characters.",
				// },
				{
					field: "tag_ref",
					headerName: "Ref",
					type: "text",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					visible: false,
					historyModalVisible: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
				},

				{
					field: "tag_name",
					headerName: "Name",
					type: "combobox",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					sortable: true,
					visible: true,
					display: "flex",

					historyModalVisible: true,
				},
				//TAG TYPE CODE FOR NEXT STAGE
				// {
				// 	field: "type",
				// 	masterField: "tag_details",
				// 	headerName: "Type",
				// 	type: "simpleSelect",
				// 	flex: 1,
				// 	defaultvalue: "", // what is inside modal fields
				// 	editable: true,
				// 	dataField2: "type",
				// 	dataField: "option",
				// 	dataField3: "text",
				// 	lookupFieldSearch: "option",
				// 	lookupFieldReturn: "text",
				// 	sortable: true,
				// 	visible: true,
				// 	dataRows: [
				// 		{ value: "label", text: "label" },
				// 		{ value: "location", text: "location" },
				// 		{ value: "item", text: "item" },
				// 	], // this is an array object from dummy .js file
				// 	fieldValidation: "", // Validation
				// },

				{
					field: "tag_details.styling.colour",
					masterField: "tag_details",
					dataField: "styling",
					dataField2: "styling",
					dataField3: "colour",

					headerName: "Colour",
					type: "singleSelect",
					flex: 0.5,
					editable: true,
					display: "flex",

					sortable: false,
					visible: true,
					historyModalVisible: true,

					filterOperators: colourFilterOperators,
					align: "center",
					disableExport: true,
					valueGetter: (value, row) => {
						value = row?.tag_details?.styling?.colour;
						return value;
					},
					
					valueSetter: (value, row) => setSubFieldExtraLevel("tag_details", "styling", 'colour', value, row),

					renderEditCell: (params) => <ColourPicker {...params} />,
					renderCell: (params) => (
						<Box
							sx={{
								backgroundColor: params?.value,
								width: 20,
								height: 20,
								borderRadius: 1,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						></Box>
					),
				},
				{
					field: "tag_details.modules",
					masterField: "tag_details",
					headerName: "Behaviours / Modules",
					type: "tagModule",
					flex: 3,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					dataField2: "modules",
					sortable: false,
					display: "flex",

					visible: true,
					fieldValidation: "",
					historyModalVisible: true,
					valueGetter: (value, row) => {
						value = row?.tag_details?.modules;
						return value;
					},
					valueSetter: (value, row) => setSubField("tag_details", "modules", value, row),

					getApplyQuickFilterFn: (value) =>
						getApplyFilterFnTagBehaviour(value, data),

					disableExport: true,
					renderCell: (params) => (
						params?.value && 
						<div
							style={{
								// whiteSpace: "pre",
								width: "100%",
								display: "flex",
								flexDirection: "column",
								pt: 5,
								// gap: 2,
								// m: "10px !important",
								whiteSpace: "normal !important",
								wordWrap: "break-word !important",
							}}
						>
							{renderTagModules(params?.value, data)
								.split("\n")
								.map((line, index) => (
									<div key={index} sx={{ pt: 2, pb: 2 }}>
										<Typography
											// sx={{ pt: 2, pb: 2 }}
											key={index}
											variant="datagrid"
										>
											{line}
										</Typography>
									</div>
								))}
						</div>
					),
				},

				{
					field: "tag_details.isEditable",
					masterField: "tag_details",
					headerName: "Editable",
					type: "staydown",
					flex: 0.5,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					dataField2: "isEditable",
					//options: [],
					historyModalVisible: false,
					display: "flex",

					align: "center",
					headerAlign: "center",
					visible: true,
					fieldValidation: "",
					valueGetter: (value, row) => {
						value = row?.tag_details?.isEditable;
						return value;
					},
					valueSetter: (value, row) => setSubField("tag_details", "isEditable", value, row),

					renderCell: (params) => {
						// console.log(params);
						if (params?.value) {
						return params?.value === 0 ||
							params?.value === "false" ||
							params?.value === false ? (
							<CloseIcon
								style={{
									color: Colours.gsgrey,
								}}
							/>
						) : (
							<CheckIcon
								style={{
									color: Colours.gsblue,
								}}
							/>
						);
					}
					},
					renderEditCell: (params) => {
						return <DataGridCheckbox ref={ref} {...params} />;
					},
				},
				{
					field: "tag_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					disableExport: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "tags",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "tags",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "tags",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (row?.tag_isDeleted === 1 || row?.tag_isDeleted === "1") {
							value = true;
						} else if (row?.tag_isDeleted === 0 || row?.tag_isDeleted === "0") {
							value = false;
						}

						return value;
					},
				},
			],
		},

		{
			gridPage: "screens", // Navigation Identifier
			pageTitle: "List Management", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "List", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataRows: lists, // pull in dummy data
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "dataRows", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: true,
			showDeleteField: "list_name",
			defaultValues: {
				list_name: "",
				list_details: {
					rows: 1,
					cols: 1,
					link: 0,
					listType: 0,
					location: "L2",
					pages: 1,
					priority: 0,
					selMax: 1,
					selMin: 0,
					stackMode: 0,
					staydown: 0,
					buttons: [],
				},
				autofilllist: false,
			},
			columns: [
				{
					field: "list_number",
					headerName: "Number",
					type: "number",
					flex: 0.5,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					visible: true,
					fieldValidation: /^\d{1,10}$/, // Validation
					historyModalVisible: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
					fieldHelper:
						"Must contain numbers, between 1-10 digits long. Check fields for errors.",
				},
				// {
				// 	field: "uid",
				// 	headerName: "Number",
				// 	type: "number",
				// 	flex: 0.5,
				// 	defaultvalue: "", // what is inside modal fields
				// 	editable: false,
				// 	sortable: true,
				// 	visible: true,
				// 	fieldValidation: /^\d{1,10}$/, // Validation
				// 	historyModalVisible: true,
				// 	display: "flex",

				// 	fieldHelper:
				// 		"Must contain numbers, between 1-10 digits long. Check fields for errors.",
				// },

				{
					field: "list_name",
					headerName: "Name ",
					type: "text",
					flex: 1.5,
					defaultvalue: "", // what is inside modal fields
					editable: true,
					sortable: true,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					fieldValidation: /^[a-zA-Z0-9',-."" % ()<>!#*/+& ]{0,64}$/, // Validation, //
					fieldHelper:
						"Name can only contain letters, numbers & special characters and must be between 1 and 64 charaters long.",
				},
				// {
				// 	masterField: "list_details",
				// 	field: "location",
				// 	headerName: "Type",
				// 	type: "listType",
				// 	flex: 0.8,
				// 	defaultvalue: 0, // what is inside modal fields
				// 	editable: false,
				// 	dataField2: "location",
				// 	sortable: true,
				// 	visible: true,
				// 	// fieldValidation: /^[a-zA-Z0-9',-.""() ]{0,64}$/, // Validation, //
				// 	// fieldHelper:
				// 	// 	"Must contain letters, numbers, special characters, between 1 and 64 charaters long.",
				// },

				{
					field: "list_details.staydown",
					headerName: "Staydown",
					type: "boolean",
					flex: 1,
					defaultvalue: 0, // what is inside modal fields
					editable: true,
					dataField2: "staydown",
					sortable: true,

					historyModalVisible: true,
					display: "flex",

					visible: true,
					valueGetter: (value, row) => {
						// if(row?.list_details?.staydown) {
						value = row?.list_details?.staydown;
						return value;
						// } 
					},
					valueSetter: (value, row) => setSubField("list_details", "staydown", value, row),
					renderCell: (params) => {
						if (params?.value) {
						return params?.value === 0 ||
							params?.value === "false" ||
							params?.value === false ? (
							<CloseIcon
								style={{
									color: Colours.gsgrey,
								}}
							/>
						) : (
							<CheckIcon
								style={{
									color: Colours.gsblue,
								}}
							/>
						);
					} else return null
					},
				
				},
				{
					field: "list_details.selMin",
					headerName: "Min Selections",
					type: "number",
					flex: 1,
					defaultvalue: 0, // what is inside modal fields
					editable: true,
					dataField2: "selMin",
					sortable: true,
					visible: true,
					align: "center",
					historyModalVisible: true,
					display: "flex",
					headerAlign: "center",
					fieldValidation: /^[0-9][0-9]?$|^10$/, // Validation
					fieldHelper: "Must be a number between 0 and 10.",
					valueGetter: (value, row) => {
						value = row?.list_details?.selMin;
						return value;
					},
					valueSetter: (value, row) => setSubField("list_details", "selMin", value, row),

					renderEditCell: (params) => (
						<GridEditInputCell
							{...params}
							inputProps={{
								max: 10,
								min: 0,
							}}
						/>
					),
				},
				{
					field: "list_details.selMax",
					headerName: "Max Selections",
					type: "number",
					flex: 1,
					defaultvalue: 0, // what is inside modal fields
					editable: true,
					dataField2: "selMax",
					sortable: true,
					align: "center",
					visible: true,
					historyModalVisible: true,
					display: "flex",

					headerAlign: "center",
					fieldValidation: /^[0-9][0-9]?$|^10$/, // Validation
					fieldHelper: "Must be a number between 0 and 10.",
					valueGetter: (value, row) => {
						value = row?.list_details?.selMax;
						return value;
					},
					valueSetter: (value, row) => setSubField("list_details", "selMax", value, row),

					renderEditCell: (params) => (
						<GridEditInputCell
							{...params}
							inputProps={{
								max: 10,
								min: 0,
							}}
						/>
					),
				},
				{
					field: "list_variant2",
					// field: "buttons",
					headerName: "Variants",
					type: "number",
					flex: 1,
					defaultvalue: 0, // what is inside modal fields
					editable: false,
					// dataField2: "buttons",
					sortable: true,
					visible: true,
					// filterOperators: listIssuesFilterOperator,
					align: "center",
					headerAlign: "center",
					historyModalVisible: false,
					display: "flex",

					valueGetter: (value, row) =>
						getVariantCount(value, row, data),
					// renderCell: (params) => {
					// 	// console.log(params.value);
					// 	return (
					// 		<Typography>
					// 			{params.value}
					// 		</Typography>
					// 	);
					// },

					// fieldValidation: /^[1-9][0-9]?$|^10$/, // Validation
					// fieldHelper: "Must be a number between 1 and 10.",
				},
				{
					field: "list_variant",
					// field: "buttons",
					headerName: "Variant number",
					type: "number",
					flex: 1,
					defaultvalue: 0, // what is inside modal fields
					editable: false,
					// dataField2: "buttons",
					sortable: true,
					visible: false,
					// filterOperators: listIssuesFilterOperator,
					align: "center",
					headerAlign: "center",
					historyModalVisible: false,
					display: "flex",

					// valueGetter: (value, row) =>
					// 	getVariantCount(value, row, data),
					// renderCell: (params) => {
					// 	// console.log(params.value);
					// 	return (
					// 		<Typography>
					// 			{params.value}
					// 		</Typography>
					// 	);
					// },

					// fieldValidation: /^[1-9][0-9]?$|^10$/, // Validation
					// fieldHelper: "Must be a number between 1 and 10.",
				},
				{
					field: "list_details.buttons",
					// field: "buttons",
					headerName: "Issues",
					type: "issues",
					flex: 1,
					defaultvalue: 0, // what is inside modal fields
					editable: false,
					dataField2: "buttons",
					sortable: true,
					visible: true,
					filterOperators: listIssuesFilterOperator,
					align: "center",
					headerAlign: "center",
					historyModalVisible: false,
					display: "flex",

					valueGetter: (value, row) =>
						getListIssuesNewDataGrid(value, row, data),
					renderCell: (params) => {
						// console.log(params.value);
						return (
							<Box>
								{params?.value === true && (
									<LightTooltip title="This list contains buttons linked to items that no longer exist.">
										<ErrorIcon sx={{ color: Colours.gsblue }} />
									</LightTooltip>
								)}
							</Box>
						);
					},

					// fieldValidation: /^[1-9][0-9]?$|^10$/, // Validation
					// fieldHelper: "Must be a number between 1 and 10.",
				},
				{
					masterField: "list_details.ignoreThisList",
					field: "ignoreThisList",
					headerName: "Disable Sending to Till",
					type: "boolean",
					flex: 1,
					defaultvalue: false, // what is inside modal fields
					editable: true,
					dataField2: "ignoreThisList",
					sortable: true,
					allowToggle: checkPermissions(userPermission, {
						page: "screens",
						// allow: true,
						action: "toggleListSending",
						strict: true,
					}),
					// visible: false,
					historyModalVisible: false,
					display: "flex",

					valueGetter: (value, row) => {
						value = row?.list_details?.ignoreThisList;
						return value;
					},
					valueSetter: (value, row) => setSubField("list_details", "ignoreThisList", value, row),

					visible: checkPermissions(userPermission, {
						page: "screens",
						// allow: true,
						action: "toggleListSending",
						strict: true,
					}),
					// fieldValidation: /^[1-9][0-9]?$|^10$/, // Validation
					// fieldHelper: "Must be a number between 1 and 10.",
				},
				
				{
					field: "list_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "screens",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					allowToggle: false,
					visible:
						checkPermissions(userPermission, {
							page: "screens",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
				},
			],
			valueGetter: (value, row) => {
				if (row?.list_isDeleted === 1 || row?.list_isDeleted === "1") {
					value = true;
				} else if (row?.list_isDeleted === 0 || row?.list_isDeleted === "0") {
					value = false;
				}

				return value;
			},
		},
		{
			gridPage: "users", // Navigation Identifier
			gridContext: "items",
			pageTitle: "Permissions", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "User", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			modalEdit: false, //  opens the modal to edit
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "data", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "plu_name",
			defaultvalue: "", // default value
			defaultValues: {
				user_number: "",
				user_name: "",
				user_details: { login: "", userType: "", policies: [] },
			},
			columns: [
				{
					field: "user_number",
					headerName: "Number",
					flex: 0.5,
					visible: true,

					// editable: checkPermissions(userPermission, {
					// 	page: "user",
					// 	// allow: true,
					// 	action: "edit",
					// }),
					historyModalVisible: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
					editable: false,
				},
		
				{
					field: "user_name",
					headerName: "Name",
					flex: 1,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					// editable: checkPermissions(userPermission, {
					// 	page: "user",
					// 	// allow: true,
					// 	action: "edit",
					// }),
					editable: true,
				},
				{
					field: "user_details.login",
					headerName: "Email Address",
					flex: 1.5,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					// editable: checkPermissions(userPermission, {
					// 	page: "user",
					// 	// allow: true,
					// 	action: "edit",
					// }),
					valueGetter: (value, row) => {
						value = row?.user_details?.login;
						return value;
					},
					valueSetter: (value, row) => setSubField("user_details", "login", value, row),

					
				},

				{
					field: "user_details.userType",
					headerName: "User Type",
					flex: 1,
					// editable: checkPermissions(userPermission, {
					// 	page: "user",
					// 	action: "edit",
					// }),
					visible: true,
					historyModalVisible: true,
					display: "flex",

					editable: true,
					type: "singleSelect",
					// valueOptions: data?.userTypes?.map((o) => {
					// 	return { value: o?.userType_number, label: o?.userType_name };
					// }),
					// valueOptions: data?.userTypes,
					valueOptions: data?.userTypes?.filter(
						(i) => i.userType_isDeleted === 0
					),
					valueFormatter: (value) => {
						if(value) {
						const val = data?.userTypes?.filter((i)=> i?.userType_isDeleted === 0)?.find(
							(option) => parseInt(option?.userType_ref) === parseInt(value)
						)?.userType_name;
						return val ? val : "User Type Not found";
					}
					},
					getOptionValue: (value) => value?.userType_ref,
					getOptionLabel: (value) => value?.userType_name,
					// valueGetter: getSubFieldPermissions,
					valueGetter: (value, row) => {
						if(row?.user_details?.userType) {
						value = row?.user_details?.userType;
						return value;
						}
					},
					valueSetter: (value, row) => setSubField("user_details", "userType", value, row),

				},
				{
					field: "user_details.policies",
					headerName: " Additional Policies",
					flex: 1.5,
					// editable: checkPermissions(userPermission, {
					// 	page: "user",
					// 	action: "edit",
					// }),
					visible: true,
					historyModalVisible: true,
					display: "flex",

					editable: true,
					// ref: permissionsRef,
					valueGetter: (value, row) => {
						const arr = [];
						row?.user_details?.policies?.map((v) => {
							v = data?.policies?.filter(
								(pol) =>
									pol?.policy_ref === v && pol?.policy_isDeleted === 0
							);
							if (v[0] !== undefined) {
								arr.push(v[0]);
							}
							return v
						});
						return arr;


					},

					valueSetter: (value, row) => setSubField("user_details", "policies", value, row),

					renderEditCell: (params) => (
						<StandardAutoComplete
							{...params}
							// ref={permissionsRef}
							options={data?.policies.filter((i) => i.policy_isDeleted === 0)}
						/>
					),
					renderCell: (params, item) => <ExpandingTagCell value={params?.value} width={params.colDef.computedWidth} />,
// renderCell: (params, item) => <ExpansionRow value={params?.value} />
					// renderCell: (params, item) => <DisplayTagCell {...params} />,
				},
				{
					field: "user_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 0.5,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "permissions",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "users",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "users",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (row?.user_isDeleted === 1 || row?.user_isDeleted === "1") {
							value = true;
						} else if (row?.user_isDeleted === 0 || row?.user_isDeleted === "0") {
							value = false;
						}

						return value;
					},
				},
			],
		},
		{
			gridPage: "policies", // Navigation Identifier
			gridContext: "policies",
			pageTitle: "Permissions", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Policy", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			modalEdit: true, //  opens the modal to edit
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "data", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "plu_name",
			defaultvalue: "", // default value
			defaultValues: {
				policy_name: "",
				policy_details: {
					rules: [],
				},
			},
			columns: [
				{
					field: "policy_number",
					headerName: "Number",
					flex: 0.5,
					editable: false,
					visible: true,
					historyModalVisible: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
				},
				{
					field: "policy_name",
					headerName: "Name",
					flex: 1,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					// editable: checkPermissions(userPermission, {
					// 	page: "permission",
					// 	action: "edit",
					// }),
					editable: true,
				},
				{
					field: "policy_details.rules",
					headerName: "Rules",
					flex: 2,
					editable: false,
					visible: true,
					historyModalVisible: true,
					display: "flex",
					valueGetter: (value, row) => {
						value = row?.policy_details?.rules;
						return value;
					},
					valueSetter: (value, row) => setSubField("policy_details", "rules", value, row),

			
					renderCell: (params) => {
						return (
							<Box
								sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
							key={params.value}
							>
								{params?.value?.map((v, i) => {
									return(
									<Box
										sx={{
											m: 0.3,
											p: 1,
											borderRadius: 2,
											backgroundColor: Colours.gsblue,
										}}
										key={JSON.stringify(v)}
									>
										<Typography sx={{ fontSize: 10, color: "white" }} key={i}>
											Page:{" "}
											{Array.isArray(v.page) &&
											v?.page &&
											!v?.page?.includes("*")
												? v?.page?.map((i) =>
														v?.page?.length > 1 ? i + ", " : i
												  )
												: Array.isArray(v.page) &&
												  v?.page &&
												  v?.page?.includes("*")
												? "All"
												: null}
											{/* v?.api}{" "} */} | Actions:{" "}
											{Array.isArray(v.page) &&
											v?.actions &&
											!v?.actions?.includes("*")
												? v?.actions.map((i) =>
														v?.actions?.length > 1 ? i + ", " : i
												  )
												: Array.isArray(v.page) &&
												  v?.actions &&
												  v?.actions?.includes("*")
												? "All"
												: null}
											{/* v?.actions}{" "} */}
											{/* | Allow: {v?.allow?.toString()} */}
										</Typography>
									</Box>
								)})}
							</Box>
							
						);
					},
				},
				{
					field: "policy_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 0.5,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					filterable:
						// checkPermissions(userPermission, {
						// 	page: "policies",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) ===
						false,
					historyModalVisible: false,
					display: "flex",

					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "policies",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "policies",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (row.policy_isDeleted === 1 || row.policy_isDeleted === "1") {
							value = true;
						} else if (
							row.policy_isDeleted === 0 ||
							row.policy_isDeleted === "0"
						) {
							value = false;
						}

						return value;
					},
				},
			],
		},
		{
			gridPage: "userTypes", // Navigation Identifier
			gridContext: "items",
			pageTitle: "UserTypes", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "User Type", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			modalEdit: false, //  opens the modal to edit
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "data", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "plu_name",
			defaultvalue: "", // default value
			defaultValues: {
				userType_name: "",
				userType_details: {
					policies: [],
				},
			},
			columns: [
				{
					field: "userType_number",
					headerName: "Number",
					flex: 0.5,
					visible: true,
					historyModalVisible: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
					editable: false,
				},
				{
					field: "userType_name",
					headerName: "Name",
					flex: 1,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					editable: true,
				},
				{
					field: "userType_details.policies",
					headerName: "Policies",
					flex: 2,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					editable: true,

				
					valueGetter: (value, row) => {
						const arr = [];
						row?.userType_details?.policies?.map((v) => {
							v = data?.policies?.filter(
								(pol) =>
									pol?.policy_ref === v && pol?.policy_isDeleted === 0
							);
							if (v[0] !== undefined) {
								arr.push(v[0]);
							}
							return v
						});
						return arr;


					},
					
					valueSetter: (value, row) => setSubField("userType_details", "policies", value, row),

					renderEditCell: (params) => (
						<StandardAutoComplete
							{...params}
							options={data?.policies.filter((i) => i.policy_isDeleted === 0)}
						/>
					),
					renderCell: (params, item) => <ExpandingTagCell value={params?.value} width={params.colDef.computedWidth} />,
				},
				// {
				// 	field: "user_details.policies",
				// 	headerName: " Additional Policies",
				// 	flex: 1.5,
				// 	// editable: checkPermissions(userPermission, {
				// 	// 	page: "user",
				// 	// 	action: "edit",
				// 	// }),
				// 	visible: true,
				// 	historyModalVisible: true,

				// 	editable: true,
				// 	// ref: permissionsRef,
				// 	valueGetter: (params) => {
				// 		// console.log(params?.row?.user_details?.policies);

				// 		if (Array.isArray(params?.row?.userType_details?.policies)) {
				// 			return params?.row?.userType_details?.policies?.map((v) => {
				// 				return data?.policies?.find(
				// 					(p) => parseInt(p?.policy_ref) === parseInt(v)
				// 				);
				// 			});
				// 		} else {
				// 			return [];
				// 		}
				// 	},

				// 	valueSetter: setSubFieldPermissions("user_details", "policies"),
				// 	renderEditCell: (params) => (
				// 		<StandardAutoComplete
				// 			{...params}
				// 			// ref={permissionsRef}
				// 			options={data?.policies}
				// 		/>
				// 	),
				// 	renderCell: (params, item) => <DisplayTagCell {...params} />,
				// },
				{
					field: "userType_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					display: "flex",

					flex: 0.5,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					historyModalVisible: false,

					sortable: true,
					filterable:
						// checkPermissions(userPermission, {
						// 	page: "permissions",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "userTypes",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "userTypes",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (
							row.userType_isDeleted === 1 ||
							row.userType_isDeleted === "1"
						) {
							value = true;
						} else if (
							row.userType_isDeleted === 0 ||
							row.userType_isDeleted === "0"
						) {
							value = false;
						}

						return value;
					},
				},
			],
		},
		{
			gridPage: "macro", // Navigation Identifier,
			gridPageAlt: "macros", // Navigation Identifier

			gridContext: "items",
			pageTitle: "UserTypes", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Macro", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			modalEdit: false, //  opens the modal to edit
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "data", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "plu_name",
			defaultvalue: "", // default value
			defaultValues: {
				macro_name: "",
				macro_details: {
					behaviour: "",
				},
			},
			columns: [
				{
					field: "macro_number",
					headerName: "Number",
					flex: 0.5,
					editable: false,
					visible: true,
					display: "flex",
					headerAlign:'left',
					align:'left',
					historyModalVisible: false,
				},

				{
					field: "macro_name",
					headerName: "Name",
					type: "string",
					flex: 2,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					editable: true,
				},
				{
					field: "macro_details.behaviour",
					headerName: "Behaviour",
					type: "string",
					flex: 2,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					editable: true,
					valueGetter: (value, row) => {
						value = row?.macro_details?.behaviour;
						return value;
					},
					valueSetter: (value, row) => setSubField("macro_details", "behaviour", value, row),

				},
				{
					field: "macro_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "macros",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "macros",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "macros",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (row.macro_isDeleted === 1 || row.macro_isDeleted === "1") {
							value = true;
						} else if (
							row.macro_isDeleted === 0 ||
							row.macro_isDeleted === "0"
						) {
							value = false;
						}

						return value;
					},
				},
			],
		},
		{
			gridPage: "priceband", // Navigation Identifier
			gridPageAlt:"pricebands",
			gridContext: "items",
			pageTitle: "UserTypes", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: false, // Display modal option
			modalTitle: "Price Band", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			modalEdit: false, //  opens the modal to edit
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "data", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "plu_name",
			defaultvalue: "", // default value
			defaultValues: {
				priceband_name: "",
				priceband_details: {
					behaviour: "",
				},
			},
			columns: [
				{
					field: "priceband_number",
					headerName: "Number",
					flex: 0.5,
					editable: false,
					visible: true,
					historyModalVisible: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
				},

				{
					field: "priceband_name",
					headerName: "Name",
					type: "string",
					flex: 2,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					editable: true,
				},
				{
					field: "priceband_details.isActive",
					headerName: "Active",
					type: "boolean",
					flex: 2,
					display: "flex",

					editable: true,
					visible: true,
					historyModalVisible: true,
					valueGetter: (value, row) => {
						value = row?.priceband_details?.isActive;
						return value;
					},
					valueSetter: (value, row) => setSubField("priceband_details", "isActive", value, row),

					renderCell: (params) => {
						return params.value === 1 || params.value === true ? (
							<CheckIcon
								style={{
									color: Colours.gsblue,
								}}
							/>
						) : (
							<CloseIcon
								style={{
									color: Colours.gsgrey,
								}}
							/>
						);
					},
				},
				{
					field: "priceband_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "pricebands",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "pricebands",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "pricebands",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (
							row.priceband_isDeleted === 1 ||
							row.priceband_isDeleted === "1"
						) {
							value = true;
						} else if (
							row.priceband_isDeleted === 0 ||
							row.priceband_isDeleted === "0"
						) {
							value = false;
						}

						return value;
					},
				},
			],
		},
		{
			gridPage: "analysisCategory", // Navigation Identifier
			gridPageAlt:"analysisCategories",

			gridContext: "items",
			pageTitle: "UserTypes", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Analysis Category", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			modalEdit: false, //  opens the modal to edit
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "data", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "plu_name",
			defaultvalue: "", // default value
			defaultValues: {
				analysisCategory_number: "",
				analysisCategory_name: "",
				analysisCategory_details: {
					type: "",
				},
			},
			columns: [
				{
					field: "analysisCategory_number",
					headerName: "Number",
					flex: 0.5,
					editable: false,
					visible: true,
					historyModalVisible: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
				},

				{
					field: "analysisCategory_name",
					headerName: "Name",
					type: "string",
					flex: 2,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					editable: true,
				},
				{
					field: "analysisCategory_details.type",
					nestedField: "type",
					headerName: "Type",
					type: "singleSelect",
					flex: 2,
					historyModalVisible: true,
					display: "flex",

					editable: true,
					visible: true,

					valueOptions: [
						{ name: "Food", value: 1 },
						{ name: "Drink", value: 2 },
						{ name: "Other", value: 3 },
					],
					getOptionValue: (value) => value.value,
					getOptionLabel: (value) => value.name,
					valueGetter: (value, row) => {
						value = row?.analysisCategory_details?.type;
						return value;
					},
					valueSetter: (value, row) => setSubField("analysisCategory_details", "type", value, row),

				},
				{
					field: "analysisCategory_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
						// checkPermissions(userPermission, {
						// 	page: "analysisCategories",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "analysisCategories",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "analysisCategories",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (
							row.analysisCategory_isDeleted === 1 ||
							row.analysisCategory_isDeleted === "1"
						) {
							value = true;
						} else if (
							row.analysisCategory_isDeleted === 0 ||
							row.analysisCategory_isDeleted === "0"
						) {
							value = false;
						}

						return value;
					},
				},
			],
		},
		{
			gridPage: "modifier", // Navigation Identifier
			gridPageAlt:"modifiers",

			gridContext: "items",
			pageTitle: "UserTypes", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Modifier", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			modalEdit: false, //  opens the modal to edit
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "data", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "plu_name",
			defaultvalue: "", // default value
			defaultValues: {
				modifier_name: "",
				modifier_number: "",

				modifier_details: {
					prefix: "",
				},
			},
			columns: [
				{
					field: "modifier_number",
					headerName: "Number",
					flex: 0.5,
					editable: true,
					visible: true,
					historyModalVisible: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
				},

				{
					field: "modifier_name",
					headerName: "Name",
					type: "string",
					flex: 2,
					editable: true,
					visible: true,
					historyModalVisible: true,
					display: "flex",
				},
				{
					field: "modifier_details.prefix",
					headerName: "Prefix",
					type: "string",
					flex: 2,
					editable: true,
					visible: true,
					historyModalVisible: true,
					display: "flex",

					valueGetter: (value, row) => {
						value = row?.modifier_details?.prefix;
						return value;
					},
					valueSetter: (value, row) => setSubField("modifier_details", "prefix", value, row),

				},
				{
					field: "modifier_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					historyModalVisible: false,
					display: "flex",

					sortable: true,
					filterable:
						// checkPermissions(userPermission, {
						// 	page: "modifiers",
						// 	// allow: true,
						// 	action: "undelete",
						// 	strict: true,
						// }) === true,
						false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "modifiers",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "modifiers",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (
							row.modifier_isDeleted === 1 ||
							row.modifier_isDeleted === "1"
						) {
							value = true;
						} else if (
							row.modifier_isDeleted === 0 ||
							row.modifier_isDeleted === "0"
						) {
							value = false;
						}

						return value;
					},
				},
			],
		},
		{
			gridPage: "paymentMethod", // Navigation Identifier
			gridPageAlt:"paymentMethods",

			gridContext: "items",
			pageTitle: "UserTypes", // Page Title
			displayToolBar: true, // Display Tool Bar
			modalButtonTitle: "New", // Edit Modal button title
			allowModalButton: true, // Display modal option
			modalTitle: "Payment Method", //Edit Modal title
			quickFilter: true, // Display quick filter
			allowImport: false, //Display Import
			allowExport: true, //Display Export
			modalEdit: false, //  opens the modal to edit
			displayColumnsButton: true, // display column button within tool bar
			displayFilterButton: true, // display filter button within tool bar
			allowRowEdit: true, // allows row to be edited
			allowRowDelete: true, // allows row to be deleted
			checkBoxDelete: true, // diplays check boxes
			dataApi: "", // API Data source (typically end of URL)
			dataSource: "data", // Data source to use
			uniqueFieldID: "id", // used for data grid and must be a unique ID
			allowNavigate: false,
			showDeleteField: "plu_name",
			defaultvalue: "", // default value
			defaultValues: {
				paymentMethod_name: "",
				paymentMethod_number: "",
				paymentMethod_details: {},
			},
			columns: [
				{
					field: "paymentMethod_number",
					headerName: "Number",
					flex: 0.5,
					// editable: checkPermissions(userPermission, {
					// 	page: "paymentMethod",
					// 	// allow: true,
					// 	action: "edit",
					// }),
					historyModalVisible: false,
					display: "flex",
					headerAlign:'left',
					align:'left',
					editable: true,
					visible: true,
				},

				{
					field: "paymentMethod_name",
					headerName: "Name",
					type: "string",
					flex: 2,
					visible: true,
					editable: true,
					historyModalVisible: true,
					display: "flex",
				},
				{
					field: "paymentMethod_isDeleted",
					headerName: "Deleted",
					type: "boolean",
					flex: 1,
					defaultvalue: "", // what is inside modal fields
					editable: false,
					sortable: true,
					historyModalVisible: false,
					display: "flex",

					filterable:
					// 	checkPermissions(userPermission, {
					// 		page: "paymentMethods",
					// 		// allow: true,
					// 		action: "undelete",
					// 		strict: true,
					// 	}) === true,
					false,
					filterOperators: getGridBooleanOperators(),
					allowToggle:
						checkPermissions(userPermission, {
							page: "paymentMethods",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					visible:
						checkPermissions(userPermission, {
							page: "paymentMethods",
							// allow: true,
							action: "undelete",
							strict: true,
						}) === true,
					valueGetter: (value, row) => {
						if (
							row.paymentMethod_isDeleted === 1 ||
							row.paymentMethod_isDeleted === "1"
						) {
							value = true;
						} else if (
							row.paymentMethod_isDeleted === 0 ||
							row.paymentMethod_isDeleted === "0"
						) {
							value = false;
						}

						return value;
					},
				},
			],
		},
	];
	return gridList.filter((item) => item.gridPage === gridPage)[0];
};
