import FormatBoldIcon from "@mui/icons-material/FormatBold";
import {
	Box,
	Checkbox,
	FormControlLabel,
	TextField,
	ToggleButton,
	Typography
} from "@mui/material";
import { motion } from "framer-motion";
import * as React from "react";
import { Colours } from "../../../assets/global/Theme-variable";
import { DataGridContext } from "../../../context/DataGridContext";
import GridContext from "../../../context/GridContext";
import controls from "../data/controls";
import { fontSizeOptions } from "../data/fontSizes";
import funcType from "../data/funcType";
import LargeListFuncType from "../data/LargeListFuncType";
import { modifiers } from "../data/modifiers";
import PaymentScreenFuncType from "../data/PaymentScreenFuncType";
import { presetServers } from "../data/presetServers";
import { textAlignOptions } from "../data/textAlignOptions";
import AutoComplete from "./AutoComplete";
import IntegratedColourPicker from "./IntegratedColourPicker";
import OptionSelect from "./OptionSelect";
import { checkPermissions } from "../../AccessControl";
import { useUserState } from "../../../context/UserContext";

const style = {
	display: "grid",
	gridTemplateColumns: "1",
	gridTemplateRows: "0.3fr 100%",
	gap: 1,
	flex: 1,
	width: "100%",
	backgroundColor: "white",
	padding: 0,
};

export default function EditSide({ openEditBar, openItem }) {
	const { data } = React.useContext(DataGridContext);
	const {
		setNewChanges,
		setVariant,
		variant,
		page,
		setNewItemModalOpen,
	} = React.useContext(GridContext);
	
const userPermission = useUserState()
	let buttonIndex = variant?.list_details?.buttons?.findIndex(function (
		object
	) {
		return parseInt(object?.id) === parseInt(openItem?.id);
	});


	const [sizeOptions, setSizeOptions] = React.useState(
		[]
	);
	const handleFormat = (event) => {
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].style.font =
				event === true
					? draft.list_details.buttons[buttonIndex].style.font.match(/\d+/)[0] +
					  "B"
					: draft.list_details.buttons[buttonIndex].style.font.match(/\d+/)[0] +
					  "N";
		});

		setNewChanges(true);
	};
	const handleShowText = (event, newFormats) => {
		const val = newFormats ? 0 : 1;
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].hideText = val;
		});
		setNewChanges(true);
	};

	React.useEffect(() => {
		if (variant) {
			const options = getButtonSizeOptions(buttonIndex);
			setSizeOptions(options);
		}
	}, [buttonIndex]);

	const handleChangeText = (event) => {
		setVariant((draft) => {
			const reg = new RegExp(
				/^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/
			);

			if (reg.test(event.target.value)) {
				draft.list_details.buttons[buttonIndex].text =
					event.target.value.replace(/(?:\r\n|\r|\n)/g, "|");
			}
		});
		setNewChanges(true);
	};

	const handleChangePLU = (event, newValue) => {
		setVariant((draft) => {
			if (variant.list_details.buttons[buttonIndex].functionType === 21) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.plu_number
					);
					draft.list_details.buttons[buttonIndex].text = newValue.plu_name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 12) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.list_number
					);
					draft.list_details.buttons[buttonIndex].text = newValue.list_name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 1) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.function_number
					);
					draft.list_details.buttons[buttonIndex].text = newValue.function_name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 3) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.number
					);
					draft.list_details.buttons[buttonIndex].text = newValue.name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 20) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.macro_number
					);
					draft.list_details.buttons[buttonIndex].text = newValue.macro_name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 2) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.paymentMethod_number
					);
					draft.list_details.buttons[buttonIndex].text =
						newValue.paymentMethod_name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 6) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.value
					);
					draft.list_details.buttons[buttonIndex].text = newValue.name;
				}
			}
			if (variant.list_details.buttons[buttonIndex].functionType === 13) {
				if (newValue !== null) {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newValue.value
					);
					draft.list_details.buttons[
						buttonIndex
					].text = `Server ${newValue.value}`;
				}
			}
		});

		setNewChanges(true);
	};

	const handleButtonType = (event) => {
		let type = event.target.value;
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].functionType = type.val;
			if (type.name === "Item") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Navigate") {
				draft.list_details.buttons[buttonIndex].functionType = 12;

				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Function") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Modifier") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Macro") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Payment Method") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			} else if (type.name === "Blank") {
				draft.list_details.buttons[buttonIndex].functionValue = 0;
				draft.list_details.buttons[buttonIndex].text = " ";
			} else if (type.name === "Control") {
			} else if (type.name === "Preset Server") {
				draft.list_details.buttons[buttonIndex].hideText = 0;
			}
		});
		setNewChanges(true);
	};

	const handleTextAlign = (event) => {
		const displayAlign = event.target.value;
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].style.alignmentV =
				displayAlign.tillValue;
			draft.list_details.buttons[buttonIndex].style.alignmentH = 1;
		});
		setNewChanges(true);
	};

	// console.log(openItem);
	function getAround(ind, col, row) {
		let indexes = [];

		ind = Number(ind);
		if (isNaN(ind)) {
			throw new Error("Index should be a number.");
		}

		function range(start, end) {
			return Array(end - start + 1)
				.fill()
				.map((_, idx) => start + idx);
		}

		let until =
			ind + row * variant.list_details.cols > 0
				? ind + row * variant.list_details.cols
				: 0;

		for (let index = ind; index < until; index += variant.list_details.cols) {
			let start = index;
			let end = index + col - 1;
			var result = range(start, end);
			result.forEach((n) => n !== ind && indexes.push(n));
		}
		return indexes;
	}
	// console.log(variant.list_details.buttons);

	function getButtonSizeOptions(ind) {
		// console.log(ind, variant.list_details.rows);

		let widths = [];
		let heights = [];
		let options = [];

		ind = Number(ind);
		if (isNaN(ind)) {
			throw new Error("Index should be a number.");
		}

		function range(start, end) {
			return Array(end - start + 1)
				.fill()
				.map((_, idx) => start + idx);
		}

		const rowSpace = [];
		variant?.list_details?.buttons
			.map((item, index) =>
				rowSpace.push({
					row: Math.floor(index / variant?.list_details.cols),
					mx: item.mx,
					my: item.my,
				})
			);

		let colInd = ind % variant?.list_details?.cols;
	
		let rowInd = Math.floor(ind / variant?.list_details?.cols);
		let start = 1;
		let end = variant?.list_details?.cols - colInd;
		let result = range(start, end);
		result.forEach((n) => widths.push(n));
		
		let rowstart = 1;
	
		let rowend = variant?.list_details?.rows * page - rowInd;

		let maxrows = range(rowstart, rowend);
		maxrows?.forEach((n) => heights?.push(n));

		heights?.forEach((h) =>
			widths?.forEach((w) =>
				options?.push({
					value: `${w}x${h}`,
					name: `${w}x${h}`,
					col: w,
					row: h,
				})
			)
		);
		
		return options;

	}
	const handleButtonSize = (event) => {
		setVariant((draft) => {
			let value = event.target.value;
			// console.log(value);
			const orig = getAround(
				buttonIndex,
				variant?.list_details?.buttons[buttonIndex].mx,
				variant?.list_details?.buttons[buttonIndex].my
			);

			// console.log(orig);
			const newarr = getAround(buttonIndex, value.col, value.row);
			// console.log(newarr);
			const toShow = orig.filter((x) => !newarr.includes(x));
			// console.log(toShow);
			const lastItem = [...newarr]?.pop();
			let lastItemColInd = lastItem % variant.list_details.cols;

			// if new button size is smaller than original button size
			if (
				value.col < variant?.list_details.buttons[buttonIndex].mx ||
				value.row < variant?.list_details.buttons[buttonIndex].my
			) {
				const variantCopy = JSON.parse(JSON.stringify(variant));
				const amendItems = variantCopy?.list_details.buttons.map((item) => {
					let index = variantCopy?.list_details.buttons.findIndex(function (
						object
					) {
						return object.id === item.id;
					});
					if (toShow.includes(index)) {
						console.log(item);
						item.my = 1;
						item.mx = 1;
					}
					return item;
				});
				draft.list_details.buttons = amendItems;
				setNewChanges(true);
			}

			// if new button size is bigger than old button size
			if (
				value.col > draft.list_details.buttons[buttonIndex].mx ||
				value.row > draft.list_details.buttons[buttonIndex].my
			) {
				const amendItems = draft.list_details.buttons.map((item) => {
					let index = draft.list_details.buttons.findIndex(function (object) {
						return object.id === item.id;
					});

					if (toShow.includes(index)) {
						item.my = 1;
						item.mx = 1;
					}

					let colInd = index % variant?.list_details.cols;

					if (newarr.includes(index)) {
						const getaroundbutton = getAround(index, item.mx, item.my);
						getaroundbutton.forEach((button) => {
							draft.list_details.buttons[button].mx = 1;
							draft.list_details.buttons[button].my = 1;
						});
						if (colInd === lastItemColInd && (item.my > 1 || item.mx > 1)) {
							draft.list_details.buttons[index + 1].mx = 1;
							draft.list_details.buttons[index + 1].my = 1;
							item.my = 0;
							item.mx = 0;
						}
						if (colInd === lastItemColInd && (item.my > 1 || item.mx > 1)) {
							draft.list_details.buttons[index + 1].mx = 1;
							draft.list_details.buttons[index + 1].my = 1;
							item.my = 0;
							item.mx = 0;
						}
						item.my = 0;
						item.mx = 0;
					}
					return item;
				});
				draft.list_details.buttons = amendItems;
			}
			draft.list_details.buttons[buttonIndex].mx = value.col;
			draft.list_details.buttons[buttonIndex].my = value.row;
		});
		setNewChanges(true);
	};
	const handleColourChange = (colour) => {
		let background = data.colours[0].palette_details.colours.find(
			(col) => col.colour === colour
		);
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].style.background =
				background.index;
		});
		setNewChanges(true);
	};

	const sortItems = (a, b) => a.list_number - b.list_number;

	const handleFontColour = (colour) => {
		let foreground = data.colours[0].palette_details.colours.find(
			(col) => col.colour === colour
		);
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].style.foreground =
				foreground.index;
		});
		setNewChanges(true);
	};


	const handleNewItem = () => {
		setNewItemModalOpen(true);
	};

	const handleFontSize = (event) => {
		setVariant((draft) => {
			draft.list_details.buttons[buttonIndex].style.font =
				event.target.value.value;
		});
		setNewChanges(true);
	};
	console.log(userPermission)
	console.log(checkPermissions(userPermission, {
		page: "screens",
		allow: false,
		action: "edit",
	}))

	return (
		<Box sx={style}>
			<Box
				sx={{
					// height: "40px",
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					mb: 1,
				}}
			>
				<Typography
					variant="h4"
					sx={{
						color: Colours.gsblue,
					}}
				>
					Configure Button
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					gap: 2,
					minWidth: "100%",
				}}
			>
				<OptionSelect
					options={
						variant?.list_details?.location === "L" ||
						variant?.list_details?.location === "F"
							? PaymentScreenFuncType
							: variant?.list_details?.location === "L2" ||
							  variant?.list_details?.location === "F3"
							? LargeListFuncType
							: funcType
					}
					value={
						variant?.list_details?.location === "L" ||
						variant?.list_details?.location === "F"
							? PaymentScreenFuncType?.find(
									(type) =>
										type?.val ===
										variant?.list_details?.buttons[buttonIndex]?.functionType
							  )
							: variant?.list_details?.location === "L2" ||
							  variant?.list_details?.location === "F3"
							? LargeListFuncType.find(
									(type) =>
										type?.val ===
										variant?.list_details?.buttons[buttonIndex]?.functionType
							  )
							: funcType.find(
									(type) =>
										type?.val ===
										variant?.list_details?.buttons[buttonIndex]?.functionType
							  )
					}
					label={"Button Type"}
					onChange={handleButtonType}
					type="buttonType"
					width={"100%"}
					disabled={checkPermissions(userPermission, {
								page: "screens",
								allow: false,
								action: "edit",
							}) === false}
				/>
				<AutoComplete
					// freeSolo={true}
					options={
						variant?.list_details?.buttons[buttonIndex]?.functionType === 21
							? data?.items?.filter((i) => i?.plu_isDeleted === 0)
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 12
							? data?.screens
									.filter(
										(list) =>
											list?.list_details?.location !== "F3" &&
											list?.list_details?.ignoreThisList !== true &&
											list?.list_variant === 0 &&
											list?.list_isDeleted === 0
									)
									.sort(sortItems)
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 3
							? modifiers(data?.modifier)
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 20
							? data?.macro?.filter((i) => i?.macro_isDeleted === 0)
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 2
							? data?.paymentMethod?.filter(
									(i) => i?.paymentMethod_isDeleted === 0
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 13
							? presetServers
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 6
							? controls
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 1
							? data?.function?.filter(
									(func) =>
										func?.function_number <= 200 &&
										func?.function_isDeleted === 0
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 0
							? [{ name: "None" }]
							: [{ name: "None" }]
					}
					value={
						// variant?.list_details?.buttons[buttonIndex]?.functionValue
						variant?.list_details?.buttons[buttonIndex]?.functionType === 21
							? data?.items?.find(
									(item) =>
										parseInt(item.plu_number) ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 12
							? data?.screens?.find(
									(item) =>
										parseInt(item.list_number) ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 3
							? modifiers(data?.modifier)?.find(
									(item) =>
										parseInt(item?.number) ===
										parseInt(
											variant?.list_details?.buttons[buttonIndex]?.functionValue
										)
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 20
							? data?.macro.find(
									(item) =>
										parseInt(item.macro_number) ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 2
							? data?.paymentMethod.find(
									(item) =>
										parseInt(item.paymentMethod_number) ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 13
							? presetServers.find(
									(item) =>
										item.value ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 6
							? controls.find(
									(item) =>
										item.value ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 0
							? { name: "None" }
							: data?.function?.find(
									(item) =>
										parseInt(item?.function_number) ===
										variant?.list_details?.buttons[buttonIndex]?.functionValue
							  )
					}
					label={
						variant?.list_details?.buttons[buttonIndex]?.functionType === 21
							? "Item"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 12
							? "Screen"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 3
							? "Modifier"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 20
							? "Macro"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 2
							? "Payment Method"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 13
							? "Server Number"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 6
							? "Control"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 1
							? "Function"
							: "Function"
					}
					onChange={handleChangePLU}
					type={
						variant?.list_details?.buttons[buttonIndex]?.functionType === 21
							? "Item"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 12
							? "Navigate"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 3
							? "Modifier"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 20
							? "Macro"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 2
							? "Payment Method"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 13
							? "Preset Server"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 0
							? "Blank"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 6
							? "Control"
							: variant?.list_details?.buttons[buttonIndex]?.functionType === 1
							? "Function"
							: "Function"
					}
					width={"100%"}
					data={data}
					
					toggleOpen={
						variant?.list_details?.buttons[buttonIndex]?.functionType === 21 &&
						handleNewItem
					}
					disabled={checkPermissions(userPermission, {
						page: "screens",
						allow: false,
						action: "edit",
					}) === false}
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
						// mb: 2,
					}}
				>
					<OptionSelect
						options={sizeOptions}
						value={sizeOptions?.find(
							(button) =>
								button?.value ===
								`${variant?.list_details?.buttons[buttonIndex]?.mx}x${variant?.list_details?.buttons[buttonIndex]?.my}`
						)}
						label={"Button Size"}
						onChange={handleButtonSize}
						type="buttonSize"
						width={"55%"}
						disabled={!checkPermissions(userPermission, {
							page: "screens",
							allow: false,
							action: "edit",
						})}
					/>
					<IntegratedColourPicker
						selectedColour={
							data?.colours[0]?.palette_details?.colours?.find(
								(col) =>
									col?.index ===
									variant?.list_details?.buttons[buttonIndex]?.style?.background
							)?.colour
						}
						handleColourChange={handleColourChange}
						type="button"
						label="Colour"
						disabled={!checkPermissions(userPermission, {
							page: "screens",
							allow: false,
							action: "edit",
						})}
					/>
				</Box>
				<FormControlLabel
					value={
						variant?.list_details?.buttons[buttonIndex]?.hideText === 0
							? true
							: false
					}
					control={
						<Checkbox
							checked={Boolean(
								variant?.list_details?.buttons[buttonIndex]?.hideText === 0
									? true
									: false
							)}
							onChange={handleShowText}
							inputProps={{ "aria-label": "show text" }}
							disableRipple
							disabled={checkPermissions(userPermission, {
								page: "screens",
								allow: false,
								action: "edit",
							}) === false}
						/>
					}
					label={
						<Typography
							sx={{
								fontSize: 12,
							}}
						>
							Show Text
						</Typography>
					}
					labelPlacement="start"
				/>
				{variant?.list_details?.buttons[buttonIndex]?.hideText === 0 && (
					<motion.div
						initial={{ y: -30, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: 30, opacity: 1 }}
						transition={{ duration: 0.3 }}
						layout
						data-isopen={
							variant?.list_detail?.buttons[buttonIndex]?.hideText === 0
						}
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							gap: 2,
						}}
					>
						<Box
							sx={{
								width: "100%",
								height: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								gap: 2,
							}}
						>
							<Box>
								<TextField
									id="outlined-controlled"
									label="Display Text"
									disabled={
										variant?.list_details?.buttons[buttonIndex]
											?.functionType === 13 || checkPermissions(userPermission, {
												page: "screens",
												allow: false,
												action: "edit",
											}) === false
									}
									rows={3}
									style={{ whiteSpace: "pre-line" }}
									multiline
									size={"small"}
									fullWidth
									value={
										variant?.list_details?.buttons[buttonIndex]
											?.functionType === 13
											? `Server ${variant?.list_details?.buttons[buttonIndex]?.functionValue}`
											: variant?.list_details?.buttons[
													buttonIndex
											  ]?.text?.replaceAll("|", "\n")
									}
									onChange={handleChangeText}
									inputProps={{
										style: { textAlign: "center" },
										// pattern: "/^[a-zA-Z0-9@s&+.!£$%^&*]+$/",
									}}
									// error={!valid}
									d
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									width: "100%",
								}}
							>
								<OptionSelect
									options={fontSizeOptions ? fontSizeOptions : []}
									value={fontSizeOptions.find(
										(size) =>
											size.name ===
											parseInt(
												variant?.list_details?.buttons[
													buttonIndex
												]?.style?.font?.match(/\d+/)[0]
											)
									)}
									label={"Text Size"}
									onChange={handleFontSize}
									width={"55%"}
									type="fontSize"
									disabled={checkPermissions(userPermission, {
										page: "screens",
										allow: false,
										action: "edit",
									}) === false}
								/>
								<IntegratedColourPicker
									selectedColour={
										data?.colours[0]?.palette_details?.colours?.find(
											(col) =>
												col?.index ===
												variant?.list_details?.buttons[buttonIndex]?.style
													?.foreground
										)?.colour
									}
									handleColourChange={handleFontColour}
									type="text"
									label="Colour"
									disabled={checkPermissions(userPermission, {
										page: "screens",
										allow: false,
										action: "edit",
									}) === false}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									width: "100%",
									gap: 2,
								}}
							>
								<OptionSelect
									options={textAlignOptions ? textAlignOptions : []}
									value={textAlignOptions.find(
										(align) =>
											align?.tillValue ===
											variant?.list_details?.buttons[buttonIndex]?.style
												?.alignmentV
									)}
									label={"Text Align"}
									onChange={handleTextAlign}
									width={"80%"}
									type="align"
									disabled={checkPermissions(userPermission, {
										page: "screens",
										allow: false,
										action: "edit",
									}) === false}
								/>
								<ToggleButton
									size="small"
									selected={Boolean(
										variant.list_details.buttons[buttonIndex].style.font.charAt(
											variant.list_details.buttons[buttonIndex].style.font
												.length - 1
										) === "B"
									)}
									value={Boolean(
										variant.list_details.buttons[buttonIndex].style.font.charAt(
											variant.list_details.buttons[buttonIndex].style.font
												.length - 1
										) === "B"
									)}
									onChange={() => {
										handleFormat(
											!Boolean(
												variant?.list_details?.buttons[
													buttonIndex
												]?.style?.font?.charAt(
													variant?.list_details?.buttons[buttonIndex]?.style
														?.font?.length - 1
												) === "B"
											)
										);
									}}
									aria-label="bold"
									disabled={checkPermissions(userPermission, {
										page: "screens",
										allow: false,
										action: "edit",
									}) === false}
								>
									<FormatBoldIcon />
								</ToggleButton>
							</Box>
						</Box>
					</motion.div>
				)}
			</Box>
		</Box>
	);
}
